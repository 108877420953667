import { WinPricePopover } from "@web/components/collective/WinPricePopover"
import { Chip } from "@web/components/shared/Chip"
import { formatPrice } from "@web/lib/formatters"
import { calculateEquilibriumPrice, cn, formatVotingPower, getVotingPower } from "@web/lib/utils"
import type { Collective, Contract, Entry, Matchup } from "@web/types"

interface CollectivePrizeProps {
  contract: Contract
  collective: Collective
  entries: Entry
  matchup?: Matchup
  className?: string
}

export function CollectivePrize({ contract, collective, entries, matchup, className }: CollectivePrizeProps) {
  const { curveDenominator } = contract ?? {}
  const {
    isActive,
    primaryColor,
    secondaryColor,
    voteCount,
    burntVoteCount,
    claimerVoteCount,
    collectiveId,
    fanVotes,
  } = collective ?? {}

  const votingPower = getVotingPower(fanVotes, voteCount, burntVoteCount, claimerVoteCount)

  const { entryCount } = entries ?? {}
  const equilbriumPrice = calculateEquilibriumPrice(
    entryCount,
    matchup?.multiplier ?? 1,
    curveDenominator,
    Number(matchup?.finalPrizePool),
  )

  const isWinner = (matchup?.winnerId === collectiveId && isActive) ?? false
  const isEliminated = !isWinner && !isActive
  const isHeldByUser = fanVotes > 0

  const Divider = () => <span className="-skew-x-12 mr-[.55rem] ml-[0.55rem] h-[1.45rem] w-[1px] bg-[#D8DADF]" />

  return (
    <div className={cn("flex items-center justify-start", className)}>
      {/* Share balance & voting power */}
      {isHeldByUser && (
        <>
          <Chip
            fullOpacity
            color={`${primaryColor}E6`} // Set opacity to 90% using hex code
            textColor={`${secondaryColor}`}
            className="mx-0 scale-[88%] pr-[.39rem] ring-[1px] ring-black/10 ring-inset"
          >
            {fanVotes}
          </Chip>
          <Divider />
          <span className="font-mono text-sm uppercase">
            {formatVotingPower(Number.isNaN(votingPower) ? 0 : votingPower)}%
          </span>
        </>
      )}

      {/* Estimated win price */}
      {matchup && !isWinner && !isEliminated && (
        <>
          {isHeldByUser && <Divider />}
          <span className="font-mono text-[.8125rem] text-gray-primary">
            {formatPrice(equilbriumPrice)} EST WIN PRICE
          </span>
          <WinPricePopover />
        </>
      )}

      {/* Matchup winner */}
      {matchup && isWinner && (
        <>
          {isHeldByUser && <Divider />}
          <span className="font-mono text-[.8125rem] text-gray-primary">MATCHUP WINNER</span>
        </>
      )}

      {/* Eliminated */}
      {isEliminated && (
        <>
          {isHeldByUser && <Divider />}
          <span className="font-mono text-[#868C9B] text-sm">ELIMINATED</span>
        </>
      )}
    </div>
  )
}
