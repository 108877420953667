import { Avatar } from "@web/components/shared/Avatar"
import { cn } from "@web/lib/utils"
import type { Activity, Matchup } from "@web/types"
import type { Collective } from "@web/types"

interface AvatarConfig {
  avatarSize: string
  ringSize: string
  paddingSize: string
  fullTextSize: string
  smallTextSize: string
}

const SIZES: Record<CollectiveAvatarProps["size"], AvatarConfig> = {
  small: {
    avatarSize: "size-[1.5rem]",
    ringSize: "ring-[.1rem]",
    paddingSize: "p-[.1rem]",
    fullTextSize: "text-[0.51rem]",
    smallTextSize: "text-[0.45rem]",
  },
  medium: {
    avatarSize: "size-[2.98rem]",
    ringSize: "ring-[.14rem]",
    paddingSize: "p-[.14rem]",
    fullTextSize: "text-[0.86rem]",
    smallTextSize: "text-[0.78rem]",
  },
  big: {
    avatarSize: "w-[3.8rem] h-[3.8rem]",
    ringSize: "ring-[.18rem]",
    paddingSize: "p-[.18rem]",
    fullTextSize: "text-[1.15rem]",
    smallTextSize: "text-[1.15rem]",
  },
}

interface CollectiveAvatarProps {
  collective: Collective | Activity
  matchup?: Matchup
  size: "small" | "medium" | "big"
  className?: string
}

export function CollectiveAvatar({ collective, matchup, size, className }: CollectiveAvatarProps) {
  const { acronym, collectiveId, isActive, collectiveImageSrc, name, primaryColor, secondaryColor, state } =
    collective ?? {}
  const isWinner = matchup?.winnerId === collectiveId

  const shouldReduceFontSize = acronym?.length === 4 && !acronym.includes("I")

  // function handleClickAvatar(event: React.MouseEvent<HTMLButtonElement>) {
  //   if (size === "small") return
  //   event.preventDefault()
  //   event.stopPropagation()

  //   analytics.click()
  //   if (state === "active")
  //     openModal(Modal.Trade, { contractSlug: contractSlug, collectiveSlug: collectiveSlug, isBuy: true })
  //   else if (state === "verifying") showInfo("Trading has ended.")
  //   else if (state === "postgame") showInfo("Trading has ended.")
  //   else if (state !== "active" && isLoggedIn) showInfo("Trading hasn't started yet")
  // }

  if (!collective) return null

  return (
    <div
      className={cn(
        "relative z-10 rounded-full bg-white transition ease-in-out",
        `${SIZES[size].ringSize}`,
        `${SIZES[size].paddingSize}`,
        { "ring-blue-secondary/40": ["pregame", "entry"].includes(state) },
        { "ring-blue-primary/90": ["active", "verifying", "paused"].includes(state) && isActive },
        { "ring-blue-primary/[10%]": ["active", "verifying", "paused"].includes(state) && !isActive },
        { "ring-green-primary": (["postgame", "finished"].includes(state) && isActive) || isWinner },
        { "ring-gray-light": ["postgame", "finished"].includes(state) && !isActive },
        className,
      )}
    >
      <div className="relative h-full w-full">
        <Avatar
          src={collectiveImageSrc}
          alt={`Collective ${name}`}
          acronym={acronym}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          className={cn(
            `z-10 ${SIZES[size].avatarSize}`,
            { [`${SIZES[size].fullTextSize}`]: !shouldReduceFontSize },
            { [`${SIZES[size].smallTextSize}`]: shouldReduceFontSize },
            { "opacity-75 brightness-[100%]": !isActive },
          )}
        />
        {/* Avatar Gradient Overlay */}
        <div className="collective-avatar-gradient z-20 mix-blend-soft-light" />
      </div>
    </div>
  )
}
