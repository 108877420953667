import { useLocation, useNavigate, useParams } from "@tanstack/react-router"
import LogotypeSplit from "@web/assets/logotype-split.svg?react"
import { Marquee } from "@web/components/layout/Marquee"
import { Avatar } from "@web/components/shared/Avatar"
import { Button } from "@web/components/ui/button"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useEntries } from "@web/hooks/queries/useEntries"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { formatPrice } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import { Modal } from "@web/types"

const events = {
  profile: "header:profile_button_click",
  balance: "header:balance_button_click",
  login: "header:login_button_click",
  menu: "header:menu_button_click",
}

export function Header() {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()
  const location = useLocation()
  const { contractSlug = DEFAULT_CONTRACT, collectiveSlug } = useParams({ strict: false })

  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const {
    data: [matchupContract] = [],
  } = useContracts(contract?.matchupContractSlug)

  const isMatchup =
    (location.pathname.includes("matchups") || location.pathname.includes("holdings")) && !!matchupContract
  const activeContract = isMatchup ? matchupContract : contract
  const { tokenSlug, state } = activeContract ?? {}

  const {
    data: [token] = [],
  } = useTokens(tokenSlug)
  const { tokenImageSrc, ticker } = token ?? {}

  const {
    data: [entry] = [],
  } = useEntries(activeContract.contractSlug)
  const { hasEntered } = entry ?? {}

  const navigate = useNavigate({ from: "/" })
  const { isLoggedIn, login, fanImageSrc, username } = useLoginContext()
  const { balance } = useTokenBalance(tokenSlug)

  function handleClickLogo() {
    if (collectiveSlug) navigate({ to: "/$contractSlug", params: { contractSlug: activeContract.contractSlug } })
    else openModal(Modal.Nav)
  }

  function handleClickProfile() {
    analytics.profile()
    openModal(Modal.Profile)
  }

  function handleClickBalance() {
    analytics.balance()
    if (hasEntered) openModal(Modal.Profile)

    // Prompt login before entering
    if (!isLoggedIn) {
      login()
      return
    }

    // Trigger enter event
    window.dispatchEvent(new Event("enterContract"))
  }

  function handleClickLogin() {
    analytics.login()
    login()
  }

  return (
    <header className="flex w-full flex-col items-center">
      <Marquee contractSlug={activeContract.contractSlug} />

      <div className="relative mt-[.7rem] mb-[.2rem] flex h-[2.25rem] w-full items-center justify-between px-4">
        <button type="button" onClick={handleClickLogo} className="absolute left-4 flex items-center">
          <LogotypeSplit className="-ml-[.2rem] mt-[.1rem] w-[8.01rem] opacity-100 transition-transform duration-75 active:scale-[98.5%]" />
          <span className="mb-[.7rem] ml-[.15rem] font-regular text-[.62rem] text-gray-primary/60 uppercase italic">
            Beta
          </span>
        </button>

        <div className="absolute right-4 flex items-center">
          {/* Logged in state */}
          {isLoggedIn && (
            <>
              <Button
                variant="outline"
                onClick={handleClickBalance}
                className="flex h-[2.25rem] rounded-full border-gray-light px-1.5"
              >
                {hasEntered && (
                  <>
                    {tokenImageSrc && (
                      <img
                        src={tokenImageSrc ?? ""}
                        alt={`Symbol for ${ticker}`}
                        className="ml-[.05rem] size-[1.5rem] rounded-full"
                      />
                    )}
                    <span
                      className={cn("mr-[.4rem] ml-[.4rem] font-medium text-[.875rem]", {
                        "text-blue-primary/85": balance === 0,
                        "text-blue-primary": balance > 0,
                      })}
                    >
                      {balance === 0 ? "0.00" : formatPrice(balance)}
                    </span>
                  </>
                )}

                {!hasEntered && (
                  <span className="mr-[.4rem] ml-[.4rem] font-medium text-[.875rem] text-blue-primary/85">
                    {state === "pregame" ? "Coming Soon" : "Claim Points"}
                  </span>
                )}
              </Button>

              <Button
                onClick={handleClickProfile}
                className=" mt-[.1rem] ml-2 h-fit rounded-full p-0 hover:scale-[102%] active:scale-[97%]"
              >
                <div className="relative">
                  <div className="absolute z-0 h-8 w-8 rounded-full bg-white" />{" "}
                  {/* White circle background to cover blue background issue */}
                  <Avatar src={fanImageSrc} alt={`Avatar of ${username}`} className="relative z-10 size-[1.85rem]" />
                </div>
              </Button>
            </>
          )}

          {/* Logged out state */}
          {!isLoggedIn && (
            <>
              <Button
                onClick={handleClickLogin}
                className="h-[2.25rem] bg-white px-[.9rem] font-semibold text-[.9375rem] text-blue-primary hover:bg-blue-light active:bg-blue-light active:bg-none"
              >
                Log in
              </Button>
              <Button
                onClick={handleClickLogin}
                className="ml-2 h-[2.25rem] bg-blue-primary px-[.9rem] font-semibold text-[.9375rem] hover:brightness-105"
              >
                Sign Up
              </Button>
            </>
          )}
        </div>
      </div>
    </header>
  )
}
