import type { PostHogConfig } from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

interface AnalyticsProviderProps {
  children: React.ReactNode
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const options: Partial<PostHogConfig> = {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    capture_pageview: false, // We capture pageviews manually in Layout.tsx
  }

  // Disable PostHog in local development to not pollute network requests
  if (import.meta.env.MODE !== "production") {
    return <>{children}</>
  }

  return (
    <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_API_KEY} options={options}>
      {children}
    </PostHogProvider>
  )
}
