import "@web/tailwind.css"

import { DynamicUserProfile } from "@dynamic-labs/sdk-react-core"
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector"
import { QueryClient, QueryClientProvider, keepPreviousData } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import { Loading } from "@web/components/shared/Loading"
// import { initSentry } from "@web/lib/sentry"
import { config } from "@web/lib/viem"
import { AnalyticsProvider } from "@web/providers/AnalyticsProvider"
import { DynamicProvider } from "@web/providers/DynamicProvider"
import ErrorBoundary from "@web/providers/ErrorBoundary"
import { LoginProvider } from "@web/providers/LoginProvider"
import { NotFoundRoute } from "@web/routes/_notFound"
import { StrictMode, Suspense, lazy } from "react"
import { createRoot } from "react-dom/client"
import { WagmiProvider } from "wagmi"
import { routeTree } from "./routeTree.gen"

// initSentry()

// Tanstack query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.PROD, // Disable retries in development
      placeholderData: keepPreviousData, // Prevent flash of empty content
    },
  },
})

// Tanstack router
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPendingComponent: Loading,
  defaultNotFoundComponent: NotFoundRoute,
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
})
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}
const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : lazy(() => import("@tanstack/router-devtools").then((res) => ({ default: res.TanStackRouterDevtools })))

// biome-ignore lint/style/noNonNullAssertion: root element should never be null
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary>
      <AnalyticsProvider>
        <DynamicProvider>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <DynamicWagmiConnector>
                <LoginProvider>
                  <Suspense>
                    <RouterProvider router={router} />
                    <TanStackRouterDevtools router={router} position="bottom-right" />
                  </Suspense>
                </LoginProvider>
                <DynamicUserProfile />
              </DynamicWagmiConnector>
              <ReactQueryDevtools buttonPosition="top-right" />
            </QueryClientProvider>
          </WagmiProvider>
        </DynamicProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  </StrictMode>,
)
