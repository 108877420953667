import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { useParams } from "@tanstack/react-router"
import Spinner from "@web/assets/spinner.svg?react"
import { AmountStepper } from "@web/components/modals/ui/AmountStepper"
import { VotePrice } from "@web/components/modals/ui/VotePrice"
import { Avatar } from "@web/components/shared/Avatar"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle } from "@web/components/ui/drawer"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useRedeemVotes } from "@web/hooks/transactions/useRedeemVotes"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { ChevronRight } from "lucide-react"
import { useEffect } from "react"
import { toast } from "sonner"
import type { Address } from "viem"

const events = {
  breakdown: "redeem_modal:price_breakdown_click",
  cancel: "redeem_modal:cancel_button_click",
  error: "redeem_modal:redeem_tx_error",
  redeem: "redeem_modal:redeem_button_click",
  success: "redeem_modal:redeem_tx_success",
}

export default function RedeemModal() {
  const analytics = useAnalytics(events)
  const { loadingNetwork } = useDynamicContext()
  const { activeModal, closeModal } = useModal()

  const { fanId } = useLoginContext()
  const { contractSlug, collectiveSlug } = useParams({ from: "/$contractSlug/$collectiveSlug" })
  if (!fanId || !contractSlug || !collectiveSlug) throw new Error("Missing fan id, contract, or collective")

  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const { contractId, tokenSlug } = contract ?? {}

  const {
    data: [collective] = [],
  } = useCollectives(contractSlug, collectiveSlug)
  const { fanVotes, collectiveId, fanbase, acronym, primaryColor, secondaryColor } = collective ?? {}

  const initialBalance = fanVotes ?? 0
  const initialAmount = initialBalance ?? 1

  const { state, execute, reset, isPlusEnabled, isMinusEnabled, onInputChange } = useRedeemVotes(
    contractId,
    collectiveId as Address,
    initialAmount,
    initialBalance,
  )
  const { status, amount, prices } = state
  const formattedVotes = `${amount} ${amount === 1 ? "share" : "shares"}`

  async function handleClickBurn() {
    analytics.redeem()

    let result: Awaited<ReturnType<typeof execute>> | undefined
    try {
      result = await execute()
      if (result?.amount) {
        const message = `Redeemed ${formattedVotes} of ${fanbase}`
        toast.success(message)
        analytics.success({ result, message })
      } else throw new Error()
    } catch (error) {
      // @ts-expect-error TODO: fix error type
      const message = `Transaction error: ${result?.error ?? error?.shortMessage ?? "Please refresh the page"}`
      toast.error(message)
      analytics.error({ error, message })
    }

    closeModal()
  }

  // Reset when the modal closes
  useEffect(() => {
    if (!activeModal) reset()
  }, [activeModal])

  if (!collective) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <Avatar
        alt={`Collective ${fanbase}`}
        acronym={acronym}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        className="-mt-8 size-16 border-4 border-white"
      />
      <DrawerHeader className="p-2">
        <DrawerTitle className="font-normal text-sm">Collect from Treasury</DrawerTitle>
      </DrawerHeader>

      <div className="flex flex-col items-center justify-center px-5">
        <VotePrice
          prices={prices}
          amount={amount}
          isLoading={status === "loading"}
          type="redeem"
          tokenSlug={tokenSlug}
        />

        <AmountStepper
          label="Voting Shares"
          amount={amount}
          color={primaryColor}
          isBuy={true}
          isPlusEnabled={isPlusEnabled}
          isMinusEnabled={isMinusEnabled}
          onInputChange={onInputChange}
          className="mt-4"
        />
      </div>

      <DrawerFooter className="w-full gap-0 px-5">
        {/* Pending state */}
        {status === "pending" && (
          <Button
            size="lg"
            variant="default"
            className="relative flex h-14 w-full items-center justify-center bg-green-secondary font-semibold text-lg transition hover:bg-[#814be6] active:bg-[#7941df]"
            disabled
          >
            Redeeming {formattedVotes}
            <Spinner className="absolute right-[.85rem] h-6 w-6 animate-spin-fast text-white" />
          </Button>
        )}

        {/* Ready state */}
        {status !== "pending" && (
          <Button
            size="lg"
            variant="default"
            onClick={handleClickBurn}
            className="relative flex h-14 w-full items-center justify-center bg-green-secondary font-semibold text-lg transition hover:bg-green-secondary hover:brightness-95 active:bg-green-secondary active:brightness-90"
            disabled={status !== "ready" || loadingNetwork}
          >
            Redeem {formattedVotes}
            <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
          </Button>
        )}

        <DrawerClose
          className="mt-4 text-[#768196] hover:opacity-90 active:opacity-50"
          onClick={() => analytics.cancel()}
        >
          Cancel
        </DrawerClose>
      </DrawerFooter>
    </DrawerContent>
  )
}
