import { createFileRoute } from "@tanstack/react-router"
import { ActivityFeed } from "@web/components/shared/ActivityFeed"
import { collectivesQueryOptions } from "@web/hooks/queries/useCollectives"
import { contractsQueryOptions } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug/$collectiveSlug/activity")({
  component: ActivityRoute,
  loader: ({ params, context: { queryClient } }) => {
    const { contractSlug, collectiveSlug } = params
    queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
    queryClient.ensureQueryData(collectivesQueryOptions(queryClient, contractSlug, collectiveSlug))
  },
})

function ActivityRoute() {
  const { contractSlug, collectiveSlug } = Route.useParams()

  return (
    <>
      <ActivityFeed contractSlug={contractSlug} collectiveSlug={collectiveSlug} />
    </>
  )
}
