import { Outlet, createFileRoute } from "@tanstack/react-router"
import { CollectiveHeader } from "@web/components/collective/CollectiveHeader"
import { CollectiveTabs } from "@web/components/collective/CollectiveTabs"
import { collectivesQueryOptions, useCollectives } from "@web/hooks/queries/useCollectives"
import { contractsQueryOptions } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug/$collectiveSlug")({
  component: CollectiveRoute,
  loader: ({ params, context: { queryClient } }) => {
    const { contractSlug, collectiveSlug } = params
    queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
    queryClient.ensureQueryData(collectivesQueryOptions(queryClient, contractSlug, collectiveSlug))
  },
})

function CollectiveRoute() {
  const { contractSlug, collectiveSlug } = Route.useParams()
  const {
    data: [collective] = [],
  } = useCollectives(contractSlug, collectiveSlug)

  return (
    <>
      <CollectiveHeader contractSlug={contractSlug} collectiveSlug={collectiveSlug} className="mt-[.8rem]" />
      <CollectiveTabs collective={collective} className="mt-[.55rem]" />
      <Outlet />
    </>
  )
}
