import { formatPrice } from "@web/lib/formatters"

interface PriceRowProps {
  label: string
  value: string | number
  sign?: string
}

export function PriceRow(props: PriceRowProps) {
  const formattedValue = typeof props.value === "string" ? props.value : formatPrice(props.value)

  return (
    <section className="mt-[.1rem] flex items-stretch justify-between py-1 font-light text-black text-sm tracking-[.01rem]">
      <span>
        {props.sign}
        {props.label}
      </span>
      <span>
        {props.sign}
        {formattedValue}
      </span>
    </section>
  )
}
