import { Children, type PropsWithChildren, type ReactElement, cloneElement, isValidElement, useMemo } from "react"

interface TabUnderlineProps extends PropsWithChildren {
  value: string
  spaceBetweenElementsPercent?: number
  onValueChange?: (value: string) => void
}

/**
 * @description
 * Wrapper-component that adds animated underline to {@link TabsTrigger} array.
 *
 *
 * Item #: SCP-012
 *
 * Object Class: Euclid
 *
 * Special Containment Procedures: SCP-012 is to be kept in a darkened room at all times.
 * If the object is exposed to light or seen by personnel using a light frequency other than infrared,
 * remove personnel for mental health screening and immediate physical.☣️
 *
 *
 * @param children - Array of {@link TabsTrigger} elements
 * @param defaultValue
 * @param spaceBetweenElementsPercent - relative value that configures spacings between elements
 * @constructor
 */
export function TabUnderline({ children, value, onValueChange, spaceBetweenElementsPercent = 3.5 }: TabUnderlineProps) {
  // filter out elements that won't be rendered (ex. the condition returned false) and get total count
  const childrenToRender = useMemo(
    () =>
      Children.toArray(children).reduce((accum, child) => {
        if (isValidElement(child)) {
          return (accum as number) + 1
        }
        return accum
      }, 0) as number,
    [children],
  )

  // calculate total amount of spacing based on value in props
  const emptySpacePercent = useMemo(
    () => spaceBetweenElementsPercent * (childrenToRender * 2),
    [childrenToRender, spaceBetweenElementsPercent],
  )

  // calculate total usable width percent
  const totalWidthPercent = useMemo(() => 100 - emptySpacePercent, [emptySpacePercent])

  // calculate width of one tab
  const singleTabWidthPercent = useMemo(
    () => totalWidthPercent / childrenToRender,
    [childrenToRender, totalWidthPercent],
  )

  // TODO: move to custom tailwind class
  const tabStyling =
    "data-[state=active]:font-semibold rounded-none data-[state=active]:bg-transparent data-[state=active]:text-black"

  function handleTabClick(value: string, onClick: () => void) {
    if (onValueChange) onValueChange(value)
    onClick()
  }

  // populate TabTrigger components with styles + decorate 'onClick' with selected tab tracking
  const modifiedChildren = useMemo(
    () =>
      Children.map(children, (child) => {
        if (isValidElement(child) && child.props.onClick && child.props.value) {
          return cloneElement(child as ReactElement, {
            onClick: () => handleTabClick(child.props.value, child.props.onClick),
            className: tabStyling,
            style: {
              width: `${singleTabWidthPercent}%`,
            },
          })
        }
        return child
      }),
    [children, singleTabWidthPercent],
  )

  // generate table to make element search O(n)
  const tabsIndexTable = useMemo(() => {
    const table: Record<string, number> = {}
    modifiedChildren?.forEach((child, index) => {
      if (isValidElement(child)) {
        const element = child as ReactElement
        table[element.props.value] = index
      }
    })
    return table
  }, [modifiedChildren])

  const selectedElementIndex: number = tabsIndexTable?.[value] ? tabsIndexTable[value] : 0

  // calculate how much spacing should be added to underline border's left position
  // considering 'justify-around' rule
  const spacingOffset =
    selectedElementIndex === 0
      ? // if it's first element - just use one offset (on the left)
        spaceBetweenElementsPercent
      : // if it's 2nd+ element - that's 1st offset of the 1st element + (elementNumber * (2nd offset of prev element + 1st offset of current element))
        spaceBetweenElementsPercent + spaceBetweenElementsPercent * 2 * selectedElementIndex

  // calculate elements' width offset, ez
  const widthOffset = selectedElementIndex * singleTabWidthPercent

  return (
    <>
      {modifiedChildren}
      <div
        className="absolute bottom-0 h-[0.15rem] rounded-tl-[0.25rem] rounded-tr-[0.25rem] bg-black transition-all"
        style={{
          left: `${spacingOffset + widthOffset}%`,
          width: `${singleTabWidthPercent}%`,
        }}
      />
    </>
  )
}
