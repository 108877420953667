import { cn } from "@web/lib/utils"
import { useState } from "react"

interface HandleProps {
  inverted?: boolean
  bgColorClass?: string
}

export function Handle(props: HandleProps) {
  const [hover, setHover] = useState(false)
  const [grabbing, setGrabbing] = useState(false)

  return (
    <>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setGrabbing(true)}
        onMouseUp={() => setGrabbing(false)}
        className={cn("absolute top-0 h-10 w-full opacity-0", grabbing ? "cursor-grabbing" : "cursor-grab")}
        aria-hidden="true"
      />
      <div
        className={cn(
          "mx-auto mt-[.8rem] mb-2 h-[.23rem] w-[2.5rem] flex-shrink-0 rounded-full transition",
          hover ? "opacity-80" : "opacity-40",
          grabbing ? "cursor-grabbing" : "cursor-grab",
          { "bg-white/40": props.inverted },
          { "bg-black/40": !props.inverted },
        )}
        style={{
          backgroundColor: props.bgColorClass || "",
        }}
      />
    </>
  )
}
