import { Outlet, createFileRoute } from "@tanstack/react-router"
import { Banner } from "@web/components/contract/Banner"
import { ContractTabs } from "@web/components/contract/ContractTabs"
import { Loading } from "@web/components/shared/Loading"
import { contractsQueryOptions, useContracts } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug")({
  component: ContractRoute,
  loader: ({ params, context: { queryClient } }) => {
    const { contractSlug } = params
    queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
  },
})

export function ContractRoute() {
  const { contractSlug } = Route.useParams()
  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const {
    data: [matchupContract] = [],
  } = useContracts(contract?.matchupContractSlug)
  const isMatchup = location.pathname.includes("matchups") && !!matchupContract
  const activeContract = isMatchup ? matchupContract : contract

  if (!activeContract) return <Loading />

  return (
    <>
      <ContractTabs className="mt-[.92rem] mb-[.4rem]" />
      <Banner contract={activeContract} className="mx-[.9rem] mt-[.05rem] mb-[.3rem]" />
      <Outlet />
    </>
  )
}
