import { queryOptions, useQuery } from "@tanstack/react-query"
import { client } from "@web/lib/hono"
import type { Address } from "viem"

async function getFan(fanId: Address) {
  const res = await client.api.fan[":fanId"].$get({ param: { fanId } })
  return await res.json()
}

async function getAuthedFan() {
  const res = await client.api.fan.$get()
  return await res.json()
}

function fanQueryOptions(fanId: Address) {
  return queryOptions({
    queryKey: ["fan", fanId],
    queryFn: () => getFan(fanId),
  })
}

function authedFanQueryOptions(isLoggedIn: boolean) {
  return queryOptions({
    queryKey: ["fan", "authed"],
    queryFn: () => getAuthedFan(),
    enabled: isLoggedIn,
  })
}

export function useFan(fanId: Address) {
  return useQuery(fanQueryOptions(fanId))
}

export function useAuthedFan(isLoggedIn: boolean) {
  return useQuery(authedFanQueryOptions(isLoggedIn))
}
