import { useLoginContext } from "@web/hooks/useLoginContext"
import { useEffect, useState } from "react"
import { type Address, erc20Abi, parseUnits } from "viem"
import { useReadContract, useWriteContract } from "wagmi"

const APPROVAL_AMOUNT = parseUnits("1000000000000000", 18)

export function useApproval(contractId: Address, tokenId: Address) {
  const { fanId } = useLoginContext()

  // Set approved by default, so the button doesn't flicker while it's loading
  const [isApproved, setIsApproved] = useState(true)

  const { data: allowance, refetch } = useReadContract({
    abi: erc20Abi,
    address: tokenId,
    functionName: "allowance",
    args: [fanId as Address, contractId],
    query: { enabled: !!fanId },
  })
  const { writeContractAsync, isPending, error } = useWriteContract()

  async function approve() {
    if (!fanId || !tokenId) return
    const hash = await writeContractAsync({
      abi: erc20Abi,
      address: tokenId,
      functionName: "approve",
      args: [contractId, APPROVAL_AMOUNT],
    })
    setIsApproved(true)
    return hash
  }

  // Recheck approval if the contract address changes
  useEffect(() => {
    refetch()
  }, [contractId, refetch])

  // Approval is given with enough allowance, that buying/spending doesn't exhaust it. That's why we check for APPROVAL_AMOUNT divided.
  useEffect(() => {
    setIsApproved((allowance || 0) > APPROVAL_AMOUNT / 1_000_000_000n)
  }, [allowance])

  return { approve, isApproved, isPending, error }
}
