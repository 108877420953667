import { useSearch } from "@tanstack/react-router"
import { CoinbaseButton } from "@web/components/buttons/CoinbaseButton"
import { Handle } from "@web/components/modals/ui/Handle"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerContent, DrawerTitle } from "@web/components/ui/drawer"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"
import { useEffect, useState } from "react"

const events = {
  transfer: "fund_modal:transfer_button_click",
  success: "fund_modal:mint_tx_success",
  error: "fund_modal:mint_tx_error",
}

export default function FundModal() {
  const analytics = useAnalytics(events)
  const { openModal, closeModal } = useModal()
  const { showSuccess, showWarning } = useToast()
  const { fanId } = useLoginContext()

  const search = useSearch({ from: "__root__" })
  const { quantity = 1, price = 0 } = search
  const purchasePrice = quantity * price

  const [isLoading, setIsLoading] = useState(false)

  const { balance } = useTokenBalance("usdc")

  function handleClickTransfer() {
    analytics.transfer()
    openModal(Modal.Deposit, { tokenSlug: "usdc" })
  }

  // Monitor for changes to the wallet balance
  useEffect(() => {
    if (!balance || balance === 0) return
    if (isLoading && balance >= price) {
      showSuccess("Successfully added funds")
      closeModal()
    } else if (isLoading && balance < price) {
      showWarning("Insufficient funds to complete purchase")
    }
    setIsLoading(false)
  }, [balance])

  if (!fanId) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <Handle />
      <DrawerTitle className="mt-3">
        {purchasePrice === 0 ? "Deposit Funds with Coinbase" : "Continue to Payment"}
      </DrawerTitle>

      <div className="flex flex-col items-center justify-center px-6">
        <p className="mt-4 mb-6 text-center text-[#717179] leading-[1.33rem]">
          {purchasePrice === 0 &&
            "Easily increase your available USDC balance using your debit card or an existing Coinbase account."}
          {purchasePrice !== 0 && "Complete purchase with your debit card or use an existing Coinbase account."}
        </p>

        <div className="relative w-full">
          <CoinbaseButton
            type="logotype"
            price={purchasePrice}
            destinationWalletAddress={fanId}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            className="h-16 w-full"
          />{" "}
        </div>

        <Button variant="link" onClick={handleClickTransfer} className="mt-2 mb-3 text-blue-primary">
          Or, transfer funds from an external wallet
          <ChevronRight className="size-4 text-blue-primary" />
        </Button>
      </div>
    </DrawerContent>
  )
}
