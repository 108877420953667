import Checkmark from "@web/assets/checkmark.svg?react"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { cn } from "@web/lib/utils"
import { Modal } from "@web/types"
import type { Address } from "viem"

interface UsernameProps {
  fanId?: Address | null
  username: string
  provider: string
  className?: string
}

const events = {
  address: "username:address_click",
}

export function Username({ fanId, username, provider, className }: UsernameProps) {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event?.preventDefault()
    event?.stopPropagation()

    analytics.address({ fanId })
    openModal(Modal.Fan, { fanId: fanId ?? undefined })
  }

  return (
    <div className={cn("flex items-center justify-start", className)}>
      <button
        type="button"
        onClick={handleClick}
        disabled={!fanId}
        className="flex cursor-pointer items-center py-1 font-semibold text-[.9375rem] text-black"
      >
        {username && <span className="ml-[.02rem] flex-none align-[.04rem] font-medium text-[.88rem]">@</span>}
        <span className="max-w-[7.5rem] truncate font-semibold">{username ?? "Based Anon"}</span>
      </button>
      {provider !== "bracket" && (
        <Checkmark
          className={cn("mt-[.025rem] ml-[.28rem] size-[.88rem] flex-none", {
            "text-purple-farcaster": provider === "farcaster",
            "text-black": provider === "twitter",
          })}
        />
      )}
    </div>
  )
}
