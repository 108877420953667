import { createFileRoute } from "@tanstack/react-router"
import { Loading } from "@web/components/shared/Loading"
import { CollectivesTable } from "@web/components/tables/CollectivesTable"
import { collectivesQueryOptions, useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug/holdings")({
  component: HoldingsRoute,
  loader: ({ params, context: { queryClient } }) => {
    const { contractSlug } = params
    queryClient.ensureQueryData(collectivesQueryOptions(queryClient, contractSlug))
  },
})

function HoldingsRoute() {
  const { contractSlug } = Route.useParams()
  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const {
    data: [matchupContract] = [],
  } = useContracts(contract?.matchupContractSlug)
  const isMatchup = location.pathname.includes("holdings") && !!matchupContract
  const activeContract = isMatchup ? matchupContract : contract

  const { data: collectives = [] } = useCollectives(activeContract.contractSlug)

  if (!collectives) return <Loading />

  return (
    <>
      <CollectivesTable collectives={collectives} isHoldings />
    </>
  )
}
