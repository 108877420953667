import { useTokens } from "@web/hooks/queries/useTokens"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { type Address, erc20Abi, formatUnits } from "viem"
import { useReadContract } from "wagmi"

export function useTokenBalance(tokenSlug: string) {
  const { fanId } = useLoginContext()

  const {
    data: [token] = [],
    isLoading: isTokenLoading,
  } = useTokens(tokenSlug)
  const { tokenId, decimals } = token ?? {}

  const { data: rawBalance, isLoading: isBalanceLoading } = useReadContract({
    abi: erc20Abi,
    address: tokenId,
    functionName: "balanceOf",
    args: [fanId as Address],
    query: {
      enabled: !!tokenId && !!fanId,
      refetchInterval: DEFAULT_POLLING_INTERVAL,
    },
  })

  const balance = Number(formatUnits(rawBalance ?? 0n, decimals ?? 18))

  return { balance, isLoading: isTokenLoading || isBalanceLoading }
}
