import { useNavigate } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import { ActivityItem } from "@web/components/shared/ActivityItem"
import { Loading } from "@web/components/shared/Loading"
import { Pagination } from "@web/components/shared/Pagination"
import { useActivity } from "@web/hooks/queries/useActivity"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useState } from "react"
import type { Address } from "viem"

interface ActivityFeedProps {
  contractSlug?: string
  collectiveSlug?: string
  fanId?: Address
  limit?: number
}

const events = {
  logo: "activity_feed:end_button_click",
}

export function ActivityFeed({ contractSlug, collectiveSlug, fanId, limit = 200 }: ActivityFeedProps) {
  const analytics = useAnalytics(events)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const type = fanId ? "fan" : collectiveSlug ? "collective" : "contract"

  const { data: activity, isLoading } = useActivity(contractSlug, collectiveSlug, fanId)

  const start = (page - 1) * limit
  const end = start + limit
  const total = activity ? Math.ceil(activity.length / limit) : 0
  const activityPage = activity?.slice(start, end)

  function handleClickLogo() {
    analytics.logo()
    navigate({ to: ".." })
  }

  const FeedEnd = () => (
    <div className="pt-[1.2rem] pb-[6rem]">
      <Logo
        onClick={handleClickLogo}
        className="mx-auto h-[1.75rem] cursor-pointer text-blue-brand transition-all active:scale-[97%]"
      />
    </div>
  )

  if (isLoading) {
    return (
      <div className="min-h-[90dvh]">
        <Loading />
      </div>
    )
  }

  if (!activityPage || activityPage.length === 0) {
    return (
      <>
        <p className="py-6 text-center text-gray-500 text-sm">No activity yet… </p>
        <FeedEnd />
      </>
    )
  }

  return (
    <>
      {activityPage.map((activity) => (
        <ActivityItem key={activity.eventId} activity={activity} type={type} />
      ))}

      {total > 1 && <Pagination page={page} setPage={setPage} total={total} />}
      <FeedEnd />
    </>
  )
}
