import { createFileRoute } from "@tanstack/react-router"
import { OwnersTable } from "@web/components/tables/OwnersTable"
import { collectivesQueryOptions } from "@web/hooks/queries/useCollectives"
import { contractsQueryOptions } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug/$collectiveSlug/")({
  component: OwnersRoute,
  loader: ({ params, context: { queryClient } }) => {
    const { contractSlug, collectiveSlug } = params
    queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
    queryClient.ensureQueryData(collectivesQueryOptions(queryClient, contractSlug, collectiveSlug))
  },
})

function OwnersRoute() {
  const { contractSlug, collectiveSlug } = Route.useParams()

  return (
    <>
      <OwnersTable contractSlug={contractSlug} collectiveSlug={collectiveSlug} />
    </>
  )
}
