import { generateOnRampURL } from "@coinbase/cbpay-js"
import Spinner from "@web/assets/spinner.svg?react"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { cn } from "@web/lib/utils"
import type { Address } from "viem"

interface CoinbaseButtonProps {
  type: "logo" | "logotype"
  price?: number
  destinationWalletAddress: Address
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  className: string
}

const events = {
  buy: "coinbase_button:buy_button_click",
}

export function CoinbaseButton({
  type = "logo",
  price,
  destinationWalletAddress,
  isLoading,
  setIsLoading,
  className,
}: CoinbaseButtonProps) {
  const analytics = useAnalytics(events)

  const onRampURL = generateOnRampURL({
    appId: import.meta.env.VITE_COINBASE_APP_ID,
    ...(price && { defaultExperience: "buy", presetCryptoAmount: price }), // Only use purchase flow if a price is set, otherwise use deposit
    destinationWallets: [{ address: destinationWalletAddress, assets: ["USDC"], supportedNetworks: ["base"] }],
    partnerUserId: destinationWalletAddress,
  })

  function handleClickBuy() {
    analytics.buy()
    setIsLoading(true)
    window.open(onRampURL, "_blank")
  }

  return (
    <Button
      variant="disappear"
      onClick={handleClickBuy}
      disabled={!destinationWalletAddress || isLoading}
      className={cn("relative bg-blue-primary p-0", { "opacity-70": isLoading }, className)}
    >
      {!isLoading && (
        <img src={`/images/${type === "logo" ? "cb-pay-logo" : "cb-pay-logotype"}.png`} alt="Coinbase Pay" />
      )}
      {isLoading && <Spinner className="size-6 animate-spin-fast text-white" />}
    </Button>
  )
}
