import { CollectiveAvatar } from "@web/components/shared/CollectiveAvatar"
import { Flag } from "@web/components/shared/Flag"
import { ScrollingText } from "@web/components/shared/ScrollingText"
import { Seed } from "@web/components/shared/Seed"
import { cn } from "@web/lib/utils"
import type { Collective } from "@web/types"

interface CollectiveCellProps {
  collective: Collective
}

export function CollectiveCell({ collective }: CollectiveCellProps) {
  const { fanbase, flagSrc, name, seed, isActive, state } = collective

  return (
    <div className="ml-4 flex cursor-pointer items-center py-[.8rem]">
      <CollectiveAvatar collective={collective} size="medium" className="mr-[1.0rem] ml-[.18rem]" />

      <div className="flex max-w-[calc(100%-5rem)] flex-col">
        <span className="flex items-center text-nowrap font-semibold italic">
          <span className="flex items-center overflow-hidden">
            {flagSrc && <Flag src={flagSrc} name={name} className="mr-1.5 flex-shrink-0" />}
            <span className={cn("inline-block max-w-full truncate", { "text-gray-primary": !isActive })}>
              {fanbase}
            </span>
          </span>
          {seed && <Seed seed={seed} state={state} isActive={isActive} className="ml-[.3rem] flex-shrink-0" />}
        </span>
        {name.length <= 20 ? (
          <span className="truncate text-[.89rem] text-gray-primary">{name}</span>
        ) : (
          <ScrollingText text={name} className="text-gray-primary text-sm tracking-[.01rem]" />
        )}
      </div>
    </div>
  )
}
