import { useParams } from "@tanstack/react-router"
import { Handle } from "@web/components/modals/ui/Handle"
import { ActivityFeed } from "@web/components/shared/ActivityFeed"
import { Loading } from "@web/components/shared/Loading"
import { OnlineIndicator } from "@web/components/shared/OnlineIndicator"
import { DrawerContent, DrawerTitle } from "@web/components/ui/drawer"
import { useContracts } from "@web/hooks/queries/useContracts"
import { DEFAULT_CONTRACT } from "@web/lib/constants"

export default function ActivityModal() {
  const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const {
    data: [matchupContract] = [],
  } = useContracts(contract?.matchupContractSlug)
  const isMatchup = location.pathname.includes("matchups") && !!matchupContract
  const activeContract = isMatchup ? matchupContract : contract

  if (!activeContract) {
    return (
      <DrawerContent className="min-h-[99dvh]">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <Handle />
      <DrawerTitle className="my-4 flex items-center">
        <OnlineIndicator backgroundColor="#22c55e" />
        <span className="font-semibold italic">{activeContract.name}</span>
      </DrawerTitle>

      <div className="h-[80%] w-full overflow-y-scroll">
        <ActivityFeed contractSlug={activeContract.contractSlug} />
      </div>
    </DrawerContent>
  )
}
