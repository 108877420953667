import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { useNavigate, useSearch } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import Spinner from "@web/assets/spinner.svg?react"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { Checkbox } from "@web/components/ui/checkbox"
import { DrawerClose, DrawerContent, DrawerFooter, DrawerHeader } from "@web/components/ui/drawer"
import { Input } from "@web/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@web/components/ui/select"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useWithdrawToken } from "@web/hooks/transactions/useWithdrawToken"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { cn, shorten } from "@web/lib/utils"
import { ChevronDown, ChevronRight } from "lucide-react"
import { useEffect, useState } from "react"
import { toast } from "sonner"
import { formatEther, isAddress } from "viem"
import { useBalance } from "wagmi"

const events = {
  cancel: "withdraw_modal:cancel_button_click",
  withdraw: "withdraw_modal:withdraw_button_click",
  withdrawError: "withdraw_modal:withdraw_tx_error",
  withdrawSuccess: "withdraw_modal:withdraw_tx_success",
}

export default function WithdrawModal() {
  const analytics = useAnalytics(events)
  const { closeModal } = useModal()
  const { showWarning } = useToast()
  const { loadingNetwork } = useDynamicContext()
  const { isLoggedIn, fanId } = useLoginContext()

  const navigate = useNavigate({ from: "/" })
  const search = useSearch({ from: "__root__" })
  const { tokenSlug = "usdc" } = search

  const { data: tokens = [] } = useTokens()
  const token = tokens.find((t) => t.tokenSlug === tokenSlug)
  const { color, tokenImageSrc, ticker } = token ?? {}

  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [target, setTarget] = useState<`0x${string}` | undefined>(undefined)

  const { data: ethBalance } = useBalance({
    address: fanId,
    query: { enabled: !!fanId },
  })
  const { balance: tokenBalance } = useTokenBalance(tokenSlug)
  const balance = tokenSlug === "eth" ? Number(formatEther(ethBalance?.value ?? 0n)) : tokenBalance
  const [min, max] = [0, balance]

  const { state, execute, reset, onInputChange } = useWithdrawToken({
    tokenSlug: tokenSlug,
    target,
    amount: min,
    balance,
    hasConfirmed,
  })
  const { status, amount } = state

  function handleChangeToken(tokenSlug: string) {
    navigate({ search: (prev) => ({ ...prev, tokenSlug }) })
  }

  function handleClickMax() {
    if (balance <= 0.0001) showWarning(`Your $${token?.ticker} balance is zero!`)
    const maxBalance = (balance - 0.0001).toFixed(4)
    onInputChange(maxBalance.toString())
  }

  function handleInputChange(value: string) {
    onInputChange(value)
  }

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    const address = e.target.value

    if (address.length <= 42) {
      setTarget(e.target.value as `0x${string}`)
    } else if (address.length > 42) {
      return
    }
  }

  function handleCheckConfirm() {
    if (!isAddress(target as `0x${string}`)) {
      toast.error("Please enter a valid wallet address")
      setTarget(undefined)
      setHasConfirmed(false)
      return
    }
    if (hasConfirmed) {
      setHasConfirmed(false)
    } else {
      setHasConfirmed(true)
    }
  }

  async function handleClickWithdraw() {
    analytics.withdraw()

    let result: Awaited<ReturnType<typeof execute>> | undefined
    try {
      result = await execute()
      if (result?.amount) {
        const message = `Withdrew ${amount} $${token?.ticker} to ${shorten(target)}`
        toast.success(message)
        analytics.withdrawSuccess({
          result,
          message,
          target,
        })
      } else throw new Error()
    } catch (error) {
      // @ts-expect-error TODO: fix error type
      const message = `Transaction error: ${result?.error ?? error?.shortMessage ?? "Please refresh the page"}`
      toast.error(message)
      analytics.withdrawError({ error, message })
    }
    closeModal()
  }

  // Reset when the token changes
  useEffect(() => {
    reset()
  }, [tokenSlug])

  // Reset when the modal closes
  useEffect(() => {
    return () => reset()
  }, [])

  if (!isLoggedIn || !token) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <div className="-mb-1 -mt-9 scale-105 overflow-hidden rounded-full border-4 border-white">
        <Logo className="size-16 bg-gradient-to-t from-blue-brand to-blue-primary p-3 text-white" />
      </div>

      <DrawerHeader>
        <span className="font-semibold">Withdraw from account</span>
      </DrawerHeader>

      <div className="flex flex-col px-6">
        <p className="px-4 text-center text-gray-primary leading-[1.33rem]">
          Funds sent to an incorrect wallet address cannot be recovered by the Bracket team.
        </p>

        <div className="input-ring relative mt-5 flex items-center p-2">
          <Select value={tokenSlug} onValueChange={handleChangeToken} defaultValue="degen">
            <SelectTrigger className="absolute left-0 w-fit border-0">
              <SelectValue>
                <div className="flex items-center">
                  <img src={tokenImageSrc ?? ""} alt={`Symbol for ${ticker}`} className="size-7 rounded-full" />
                  <ChevronDown className="size-4 stroke-[3]" style={{ color: color }} />
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="font-bold">
              {tokens
                .filter((token) => !token.tokenSlug.startsWith("pts") && token.tokenSlug !== "usbg")
                .map((token) => {
                  return (
                    <SelectItem key={token.tokenId} value={token.tokenSlug}>
                      <span className="uppercase italic">${token.tokenSlug}</span>
                    </SelectItem>
                  )
                })}
            </SelectContent>
          </Select>

          <Input
            id="amount"
            type="number"
            pattern="[0-9]*"
            inputMode="decimal"
            value={Number.isNaN(amount) ? "0" : amount.toString()}
            min={min}
            max={max}
            onChange={(e) => handleInputChange(e.target.value)}
            className={cn(
              "w-full border-0 border-none bg-none p-0 text-center font-semibold italic",
              { "text-[1.4rem]": amount.toString().length > 3 },
              { "text-[1.8rem]": amount.toString().length <= 3 },
            )}
            style={{ color: token.color }}
          />
          <button
            type="button"
            onClick={handleClickMax}
            className="absolute right-0 m-2 rounded-lg px-3 py-1.5 text-center"
            style={{ backgroundColor: token.color }}
          >
            <span className="font-mono font-semibold text-white uppercase tracking-wider">Max</span>
          </button>
        </div>
        <Input
          type="text"
          placeholder="Paste wallet address..."
          value={target ?? ""}
          disabled={hasConfirmed}
          onChange={onChangeInput}
          className={cn(
            "input-ring mt-2 flex h-14 items-center justify-center text-center font-mono text-xl placeholder:text-[#848EA0] placeholder:text-sm disabled:bg-gray-300 disabled:text-[.74rem] min-[425px]:disabled:text-[.88rem]",
            { "text-blue-primary": isAddress(target as string) && target?.length === 42 },
            { "text-red-primary": !isAddress(target as string) && target?.length === 42 },
          )}
        />

        <div className="mx-auto mt-5 mb-2 flex items-center space-x-[.5rem] text-gray-primary">
          <Checkbox
            id="confirm"
            checked={hasConfirmed}
            onCheckedChange={handleCheckConfirm}
            className="size-5 border-none bg-[#D9D9D9] checked:bg-[#D9D9D9]"
          />
          <label htmlFor="confirm">I&apos;ve confirmed my address is correct</label>
        </div>
      </div>

      <DrawerFooter className="w-full gap-0 px-5">
        {/* Pending withdraw */}
        {status === "pending" && (
          <Button size="lg" className="relative h-14 font-semibold text-[1.0625rem] " disabled>
            Withdrawing {token.ticker}
            <Spinner className="absolute right-4 h-6 w-6 animate-spin-fast text-white" />
          </Button>
        )}

        {/* Ready for withdraw */}
        {status !== "pending" && (
          <Button
            size="lg"
            onClick={handleClickWithdraw}
            className="relative h-14 font-semibold text-[1.0625rem] "
            disabled={!hasConfirmed || status !== "ready" || loadingNetwork}
          >
            Withdraw ${token.ticker}
            <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
          </Button>
        )}

        <DrawerClose
          className="mt-[.9rem] text-[.9375rem] text-gray-dark hover:opacity-90 active:opacity-50"
          onClick={() => analytics.cancel()}
        >
          Close
        </DrawerClose>
      </DrawerFooter>
    </DrawerContent>
  )
}
