import { useParams, useSearch } from "@tanstack/react-router"
import Spinner from "@web/assets/spinner.svg?react"
import { Handle } from "@web/components/modals/ui/Handle"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { Carousel, type CarouselApi, CarouselContent, CarouselItem } from "@web/components/ui/carousel"
import { DrawerContent } from "@web/components/ui/drawer"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useApproval } from "@web/hooks/transactions/useApproval"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { cn } from "@web/lib/utils"
import { useEffect, useState } from "react"

const events = {
  next: "entry_modal:next_button_click",
  approve: "entry_modal:approve_button_click",
  approveError: "entry_modal:approve_tx_error",
  approveSuccess: "entry_modal:approve_tx_success",
}

export default function EntryModal() {
  const analytics = useAnalytics(events)
  const { closeModal } = useModal()
  const { showSuccess, showError } = useToast()

  const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
  const search = useSearch({ from: "__root__" })
  const { page = 0 } = search

  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const { contractId, tokenId, entryInfo } = contract ?? {}
  const { approve, isApproved, isPending, error: approveError } = useApproval(contractId, tokenId)

  // Carousel state
  const [api, setApi] = useState<CarouselApi>()
  const [current, setCurrent] = useState(page)

  async function handleClickNext() {
    analytics.next({ page: current })

    if (api?.canScrollNext()) {
      api?.scrollNext()
      return
    }

    if (isApproved) {
      closeModal()
      return
    }

    try {
      await approve()
      showSuccess("Bracket Activated")
      analytics.approveSuccess()
      closeModal()
    } catch (error) {
      showError("Activation error: Please try again...")
      analytics.approveError({ error, approveError })
    }
  }

  useEffect(() => {
    if (!api) return
    api.scrollTo(current)
    api.on("select", () => setCurrent(api.selectedScrollSnap()))
  }, [api])

  if (!entryInfo) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  const isLastSlide = current === entryInfo?.length - 1

  return (
    <DrawerContent>
      <Handle />

      <div className="flex flex-col items-center justify-center px-5">
        <div className="relative">
          <div className="floating-item mx-auto h-[13rem] max-w-[20rem] opacity-0 mix-blend-multiply">
            <img
              src="https://assets.bracket.game/packs/football.png"
              alt="Pack for Based Olympics"
              className="rotate-[3deg] transition hover:rotate-[0deg] hover:scale-[101%] active:scale-[98%]"
            />
          </div>

          <Carousel setApi={setApi} className="absolute inset-0">
            <CarouselContent className="h-full">
              {entryInfo.map((slide, index) => (
                <CarouselItem
                  // biome-ignore lint/suspicious/noArrayIndexKey: TODO: better index key for slides
                  key={index}
                  className="flex flex-col items-center justify-center bg-white bg-opacity-[.89] pb-3 text-center backdrop-blur-sm"
                >
                  <h3 className="font-semibold text-xl tracking-tight">{slide.header}</h3>
                  <button
                    type="button"
                    onClick={() => api?.scrollNext()}
                    className="text-[1.125rem] text-gray-primary leading-tight"
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: entry slides can be dynamically set from db
                    dangerouslySetInnerHTML={{ __html: slide.content }}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>

        <Button
          size="lg"
          variant="default"
          disabled={isPending}
          onClick={handleClickNext}
          className={cn(
            "relative mt-2 mb-7 w-full px-8 font-semibold text-lg text-white active:brightness-90",
            { "bg-blue-primary hover:bg-blue-primary/90 active:bg-blue-primary/80": isLastSlide },
            { "bg-black active:bg-black": !isLastSlide },
          )}
        >
          {!isPending && entryInfo[current]?.button}
          {isPending && (
            <>
              <span className="mr-2">Activating...</span>
              <Spinner className="absolute right-3 size-6 animate-spin-fast text-white" />
            </>
          )}
        </Button>
      </div>
    </DrawerContent>
  )
}
