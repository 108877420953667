import type { Column } from "@tanstack/react-table"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { cn } from "@web/lib/utils"
import { ArrowDown, ArrowUp } from "lucide-react"
import type { CSSProperties } from "react"

interface ColumnHeaderProps<T = unknown> {
  column: Column<T, unknown>
  position?: "left" | "right"
  className?: string
  children?: React.ReactNode
  disableSorting?: boolean
}

const events = {
  sort: "column_header:sort_button_click",
}

export function ColumnHeader<T>({
  column,
  position = "left",
  className,
  children,
  disableSorting,
}: ColumnHeaderProps<T>) {
  const analytics = useAnalytics(events)
  const sortDirection = column.getIsSorted()

  function handleSorting() {
    analytics.sort({ column: column.id })

    if (disableSorting) {
      return
    }

    column.toggleSorting(!column.getIsSorted() || column.getIsSorted() === "asc")
  }

  function renderSortArrows() {
    if (!sortDirection || disableSorting) return null

    const iconStyle: CSSProperties = {
      width: "0.92rem",
      height: "0.92rem",
    }

    return (
      <span
        className={`${position === "right" ? "-ml-[0.2rem] mr-[.04rem] mb-[.03rem]" : "-mr-[0.15rem] ml-[0.04rem]"} relative h-[0.92rem] w-[0.92rem] scale-x-90`}
      >
        {sortDirection === "asc" && <ArrowUp style={iconStyle} className="absolute scale-x-90" />}
        {sortDirection === "desc" && <ArrowDown style={iconStyle} className="absolute scale-x-90" />}
      </span>
    )
  }

  return (
    <div className={cn(className, { "float-right": position === "right" })}>
      <Button
        variant="ghost"
        onClick={handleSorting}
        className={cn(
          "mt-1 h-fit px-[.5rem] py-[.2rem] font-mono font-normal text-[0.8125rem] text-gray-link uppercase tracking-[.02rem]",
          {
            "bg-blue-primary/5 text-blue-primary hover:bg-none ": column.getIsSorted() && !disableSorting,
          },
        )}
      >
        {position === "right" && renderSortArrows()}
        {children}
        {position === "left" && renderSortArrows()}
      </Button>
    </div>
  )
}
