import { BRACKET_ABI } from "@contract/bracket"
import { useContractState } from "@web/hooks/useContractState"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useVotePrice } from "@web/hooks/useVotePrice"
import { SLIPPAGE } from "@web/lib/constants"
import { checkReceipt } from "@web/lib/trade"
import { publicClient } from "@web/lib/viem"
import { useEffect, useReducer } from "react"
import type { Address } from "viem"
import { useWriteContract } from "wagmi"

const COOLDOWN_TIME = 1_000

type State = {
  status: "paused" | "loading" | "ready" | "pending" | "success" | "error"
  amount: number
  balance: number
  isBuy: boolean
  prices: {
    base: bigint
    protocol: bigint
    collective: bigint
    pool: bigint
    slippage: bigint
    total: bigint
  }
  hash?: `0x${string}`
  error?: string
}

type Action =
  | { type: "paused" }
  | { type: "updated"; amount?: number; balance?: number; isBuy?: boolean; prices?: State["prices"] }
  | { type: "cooldown" }
  | { type: "executed" }
  | { type: "succeeded"; hash: `0x${string}` }
  | { type: "failed"; error?: string }
  | { type: "reset"; isBuy: boolean }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "paused":
      return { ...state, status: "paused" }
    case "updated":
      return {
        ...state,
        status: "loading",
        amount: action.amount ?? state.amount,
        balance: action.balance ?? state.balance,
        isBuy: action.isBuy ?? state.isBuy,
        prices: action.prices ?? state.prices,
      }
    case "cooldown":
      return { ...state, status: "ready" }
    case "executed":
      return { ...state, status: "pending" }
    case "succeeded":
      return { ...state, status: "success", hash: action.hash }
    case "failed":
      return { ...state, status: "error", error: action.error }
    case "reset":
      return { ...init({ isBuy: action.isBuy }) }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

function init(initialState: Partial<State>): State {
  return {
    status: "loading",
    amount: initialState.amount ?? 1,
    balance: initialState.balance ?? 0,
    isBuy: initialState.isBuy ?? true,
    prices: {
      base: 0n,
      protocol: 0n,
      collective: 0n,
      pool: 0n,
      slippage: 0n,
      total: 0n,
    },
  }
}

function convert(price: ReturnType<typeof useVotePrice>["buyPrice"], isBuy: boolean) {
  if (!price) return init({}).prices
  const slippage = isBuy ? (price.total * SLIPPAGE) / 100n : 0n

  return {
    base: BigInt(price.base),
    protocol: BigInt(price.protocolFee),
    collective: BigInt(price.collectiveFee),
    pool: BigInt(price.poolFee),
    slippage,
    total: BigInt(price.total) + slippage,
  }
}

function validate(amount: number, balance: number, isBuy: boolean, fan?: Address, collective?: Address) {
  // Buy validation
  if (isBuy) {
    if (!fan) return "Please login to continue"
    if (!collective) return "Please select a collective"
    if (amount <= 0) return "Please enter a valid amount"
    // TODO: add more cases

    return undefined
  }

  // Sell validation
  if (!isBuy) {
    if (!fan) return "Please login to continue"
    if (!collective) return "Please select a collective"
    if (amount <= 0) return "Please enter a valid amount"
    if (amount > balance) return "Insufficient balance"
    // TODO: add more cases

    return undefined
  }

  // Default error
  return "Invalid transaction"
}

export function useTradeVotes({
  contract,
  collective,
  amount,
  balance,
  isBuy = true,
}: {
  contract?: Address
  collective?: Address
  amount?: number
  balance?: number
  isBuy?: boolean
}) {
  const { fanId } = useLoginContext()

  const [state, dispatch] = useReducer(reducer, { amount, balance, isBuy }, init)

  const validationError = validate(state.amount, state.balance, state.isBuy, fanId, collective)
  const { isTradingPaused } = useContractState(contract)
  const { writeContractAsync, error } = useWriteContract()

  const prices = useVotePrice(contract, collective, state.amount, isTradingPaused || state.status === "pending") // Lock the price once the user executes the transaction
  const price = state.isBuy ? prices.buyPrice : prices.sellPrice

  function onInputChange(value: string) {
    // TODO: add debouncing
    const amount = Number.parseInt(value, 10) || 0
    dispatch({ type: "updated", amount })
  }

  async function execute() {
    if (!contract || !collective) return
    if (state.status !== "ready") return
    dispatch({ type: "executed" })

    // const maxValue = state.isBuy ? state.prices.total : 0n
    const hash = await writeContractAsync({
      abi: BRACKET_ABI,
      address: contract,
      functionName: state.isBuy ? "buyVotes" : "sellVotes",
      args: [collective, BigInt(state.amount), 0n], // maxValue], NOTE: disabled slippage
    })

    const error = await checkReceipt(hash, fanId as Address, publicClient)
    if (error) {
      dispatch({ type: "failed", error })
      return { error }
    }

    dispatch({ type: "succeeded", hash })
    return { amount: state.amount }
  }

  // Pause if transactions are disabled
  useEffect(() => {
    if (isTradingPaused) dispatch({ type: "paused" })
  }, [isTradingPaused])

  // Validate the transaction before proceeding
  useEffect(() => {
    if (validationError) dispatch({ type: "failed", error: validationError })
  }, [validationError])

  // Failed transactions produce an error
  useEffect(() => {
    if (error) dispatch({ type: "failed", error: error.message })
  }, [error])

  // Price changes automatically trigger updates
  useEffect(() => {
    if (!price) return
    dispatch({ type: "updated", prices: convert(price, state.isBuy) })

    // Provide brief cooldown before allowing user to trade
    const id = setTimeout(() => dispatch({ type: "cooldown" }), COOLDOWN_TIME)
    return () => clearTimeout(id)
  }, [price])

  return {
    state,
    execute,
    update: (isBuy: boolean) => dispatch({ type: "updated", isBuy }),
    reset: (isBuy: boolean) => dispatch({ type: "reset", isBuy }),
    isPlusEnabled: state.isBuy ? true : state.amount < state.balance,
    isMinusEnabled: state.amount > 1,
    onInputChange,
  }
}
