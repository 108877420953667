import { cn } from "@web/lib/utils"

interface SeedProps {
  seed: string
  state: string
  isActive: boolean
  className?: string
}

export function Seed({ seed, state, isActive, className }: SeedProps) {
  return (
    <span
      className={cn(
        "-tracking-[.06rem] font-medium font-mono text-[.79rem] not-italic",
        { "text-blue-primary/60": isActive && state !== "postgame" },
        {
          "text-green-secondary": isActive && (state === "postgame" || state === "finished"),
        },
        { "text-gray-primary/70": !isActive },
        className,
      )}
    >
      {seed}
    </span>
  )
}
