import { Loading } from "@web/components/shared/Loading"
import { collectiveColumns } from "@web/components/tables/columns/collectives"
import { DataTable } from "@web/components/ui/data-table"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useEntries } from "@web/hooks/queries/useEntries"
import { calculatePointsPrize, cn } from "@web/lib/utils"
import type { Matchup } from "@web/types"

interface MatchupTableProps {
  matchup: Matchup
  showTableEnd?: boolean
}

export function MatchupTable({ matchup, showTableEnd = false }: MatchupTableProps) {
  const { contractSlug, homeCollectiveSlug, awayCollectiveSlug, finalPrizePool, multiplier, title, state, winnerId } =
    matchup ?? {}
  const {
    data: [homeCollective] = [],
  } = useCollectives(contractSlug, homeCollectiveSlug)
  const {
    data: [awayCollective] = [],
  } = useCollectives(contractSlug, awayCollectiveSlug)

  const winnerSlug =
    winnerId === homeCollective?.collectiveId
      ? homeCollective?.collectiveSlug
      : winnerId === awayCollective?.collectiveId
        ? awayCollective?.collectiveSlug
        : null

  const {
    data: [entries] = [],
  } = useEntries(contractSlug)
  const { entryCount } = entries ?? {}
  const prizePool = finalPrizePool
    ? Math.round(Number(finalPrizePool) / 1e21)
    : Math.round(calculatePointsPrize(entryCount, multiplier) / 1_000)

  if (!homeCollective || !awayCollective) {
    return <Loading />
  }

  return (
    <div className="-mt-1.5 relative mx-1.5 mb-1 space-y-3">
      <div
        className={cn(
          "absolute inset-0 top-[6.5rem] z-0 h-[30px] blur-[40px]",
          { "opacity-100": ["pregame", "entry", "active", "verifying", "paused"].includes(state) },
          { "opacity-10": ["postgame", "finished"].includes(state) },
        )}
        style={{
          background: `linear-gradient(to bottom, ${awayCollective.primaryColor}, ${homeCollective.primaryColor})`,
        }}
      />
      <DataTable
        columns={collectiveColumns}
        data={[awayCollective, homeCollective]}
        meta={{
          isMatchup: true,
          isActive: ["pregame", "entry", "active", "verifying", "paused"].includes(state),
          winnerSlug,
          matchupTitle: `${title} | ${Number.isNaN(prizePool) ? 0 : prizePool}K POOL`,
          showTableEnd,
          disableSorting: true,
        }}
      />
    </div>
  )
}
