import { PercentChange } from "@web/components/shared/PercentChange"
import { formatPriceString } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"

interface PriceCellProps {
  price: string
  decimals: number
  state: string
  updatedAt: number
  isActive: boolean
  averageBuyPrice?: string
}

export function PriceCell({ price, decimals, state, updatedAt, isActive, averageBuyPrice }: PriceCellProps) {
  const formattedPrice = ["pregame", "entry"].includes(state) ? "--" : formatPriceString(price, 2, decimals)
  const currentTime = Math.floor(Date.now() / 1000)
  const isPriceStale = currentTime - updatedAt > 60 * 30 // 30 minutes

  return (
    <div className="mr-[1.1rem] flex h-full cursor-pointer flex-col items-end justify-center py-3 text-right">
      <div className="flex items-center justify-center">
        <span
          className={cn("mt-[.1rem] mb-[.05rem] font-semibold text-[.875rem] tracking-[.01rem]", {
            "text-gray-primary opacity-80": state === "pregame" || !isActive,
          })}
        >
          {formattedPrice}
        </span>
      </div>

      {averageBuyPrice && (
        <div>
          <span
            className={cn("text-gray-primary text-xs", {
              "opacity-80": !isActive,
              "opacity-50": averageBuyPrice === "0",
            })}
          >
            {averageBuyPrice === "0" ? "--" : `AVG ${formatPriceString(averageBuyPrice, 2, decimals)}`}
          </span>
        </div>
      )}

      {!averageBuyPrice && (
        <PercentChange
          percent={0}
          showZero={["pregame", "entry", "postgame", "finished"].includes(state) || isPriceStale}
          isActive={isActive}
          className="text-[0.8125rem]"
        />
      )}
    </div>
  )
}
