import { cn } from "@web/lib/utils"

interface OnlineIndicatorProps {
  backgroundColor?: string
  className?: string
}

export function OnlineIndicator({ backgroundColor = "#ffffff", className }: OnlineIndicatorProps) {
  return (
    <div className={cn("relative mr-1.5 inline-flex size-3 items-center justify-center", className)}>
      <span
        className="absolute size-3 h-full w-full animate-ping rounded-full opacity-70"
        style={{ backgroundColor }}
      />
      <span className="size-2 rounded-full" style={{ backgroundColor }} />
    </div>
  )
}
