import { Link, createFileRoute } from "@tanstack/react-router"

export const Route = createFileRoute("/_notFound")({
  component: NotFoundRoute,
})

export function NotFoundRoute() {
  return (
    <div className="flex grow flex-col justify-center text-center">
      <h1 className="mt-6 font-semibold text-2xl">Oops! Something went wrong</h1>
      <p className="mt-2">That page does not exist, or an error has occurred</p>

      <Link to="/" className="mt-8 text-xl underline underline-offset-2">
        Go back home
      </Link>
    </div>
  )
}
