import { useNavigate } from "@tanstack/react-router"
import Spinner from "@web/assets/spinner.svg?react"
import { Button } from "@web/components/ui/button"
import { useEntryMutations } from "@web/hooks/mutations/useEntryMutations"
import { useApproval } from "@web/hooks/transactions/useApproval"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { cn } from "@web/lib/utils"
import { type Contract, type Entry, Modal } from "@web/types"
import { ChevronRight } from "lucide-react"
import { useEffect, useState } from "react"

interface EntryButtonProps {
  contract: Contract
  entries: Entry
}

const events = {
  enter: "entry_button:enter_button_click",
  approveError: "entry_button:approve_tx_error",
  approveSuccess: "entry_button:approve_tx_success",
}

export function EntryButton({ contract, entries }: EntryButtonProps) {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()
  const { showError } = useToast()
  const navigate = useNavigate({ from: "/" })
  const { isLoggedIn, login } = useLoginContext()

  const { name, contractId, contractSlug, tokenId } = contract ?? {}

  const [isLoading, setIsLoading] = useState(false)
  const [loadingLabel, setLoadingLabel] = useState("Entering...")
  const [hasEntered, setHasEntered] = useState(entries?.hasEntered ?? false)
  const { createEntry } = useEntryMutations()
  const { approve, isApproved, error: approveError } = useApproval(contractId, tokenId)

  async function handleClickEnter() {
    analytics.enter({ contractSlug })

    // Exit early if user has already entered
    if (hasEntered) return

    // Prompt login before entering
    if (!isLoggedIn) {
      login()
      return
    }

    // Approve contract token and create new entry
    setIsLoading(true)
    try {
      if (!isApproved) {
        setLoadingLabel("Approving Contract...")
        await approve()
        analytics.approveSuccess()
      }

      navigate({ to: "/$contractSlug/holdings", params: { contractSlug } })
      openModal(Modal.Entry)

      setLoadingLabel("Airdropping Points...")
      setTimeout(() => setLoadingLabel("Minting Shares..."), 3000)

      const entry = await createEntry({ contractSlug })
      if (!entry) throw new Error("Failed to create entry")

      setHasEntered(true)
    } catch (error) {
      console.error(error)
      showError("Error: Please refresh the page and wait 5 seconds")
      analytics.approveError({ error: approveError })
    } finally {
      setIsLoading(false)
      setLoadingLabel("Entering...")
    }
  }

  // Listen for entry event from header
  useEffect(() => {
    window.addEventListener("enterContract", handleClickEnter)
    return () => {
      window.removeEventListener("popstate", handleClickEnter)
    }
  }, [])

  return (
    <Button
      size="lg"
      disabled={hasEntered || isLoading}
      onClick={handleClickEnter}
      className={cn(
        "relative mb-1 flex h-[3.15rem] w-full items-center justify-center overflow-hidden bg-blue-primary transition hover:bg-blue-primary active:bg-blue-primary",
        { hidden: hasEntered },
      )}
    >
      <div className="relative z-10 flex items-center">{isLoading ? loadingLabel : `Enter ${name}`}</div>

      <div className="-left-1 -skew-x-12 absolute inset-0 rounded-tr-[.18rem] rounded-br-[.18rem] bg-blue-primary/100 bg-gradient-to-b from-[#7FA4FF]/10 to-blue-brand/50" />
      {!isLoading && <ChevronRight className="absolute right-3 ml-[.15rem] size-6 text-[#d1dffc]" />}
      {isLoading && <Spinner className="absolute right-3 size-6 animate-spin-fast text-white" />}
    </Button>
  )
}
