import type { GameState } from "@api/types"
import { useParams } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import { CountdownClock } from "@web/components/faqs/CountdownClock"
import { FAQs } from "@web/components/faqs/FAQs"
import { PrizePoolBlock } from "@web/components/faqs/PrizePoolBlock"
import { TimelineBlock } from "@web/components/faqs/TimelineBlock"
import { Handle } from "@web/components/modals/ui/Handle"
import { Loading } from "@web/components/shared/Loading"
import { DrawerContent, DrawerHeader } from "@web/components/ui/drawer"
import { ScrollArea } from "@web/components/ui/scroll-area"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { sharePage } from "@web/lib/utils"
import { Share } from "lucide-react"

const events = {
  share: "general_faqs_modal:share_button_click",
}

const titles: Record<GameState, string> = {
  pregame: "Bracket Pregame",
  entry: "Bracket Pregame",
  active: "Bracket in Progress",
  verifying: "Bracket Postgame",
  postgame: "Bracket Postgame",
  finished: "Bracket Postgame",
  paused: "Bracket Paused",
}

export default function FAQsModal() {
  const analytics = useAnalytics(events)
  const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })

  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const { state, mainFAQs, startDate, poolPct, prizePoolInfo, timelineFAQs } = contract ?? {}
  const { header, subheader, description, questions } = mainFAQs ?? {}

  function handleClickShare() {
    analytics.share()
    sharePage(`Bracket ${subheader} FAQs`, `Everything you need to know about ${subheader} Bracket`)
  }

  if (!contract) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    // BRACKET DETAILS DRAWER
    <DrawerContent
      className={`${state === "postgame" ? "bg-gradient-to-b from-green-primary/40 to-blue-brand/80" : "bg-gradient-to-b from-[#7FA4FF]/20 to-blue-brand/80"} mt-[2rem] overflow-hidden border-none bg-white/0 text-white backdrop-blur-2xl`}
    >
      <Handle bgColorClass="#ffffff " />

      <DrawerHeader className="-mt-[.7rem] flex w-full flex-row items-center justify-center border-white/30 border-b md:border-b-0">
        <span className="mb-0 font-semibold">{titles[state as GameState]}</span>
        <button
          type="button"
          onClick={handleClickShare}
          className="-top-[2.3rem] absolute right-4 mt-[3.65rem] flex items-center justify-center border-none bg-transparent p-2"
        >
          <Share className=" h-[1.45rem] w-[1.45rem] text-white/75 transition hover:text-white active:text-white/50" />
        </button>
      </DrawerHeader>

      <ScrollArea className="relative mb-0 flex w-full flex-col px-6">
        <div className="flex justify-between">
          {/* Bracket Title */}
          <h1 className="mt-[1.4rem] max-w-[80%] text-[2.25rem] leading-[2.43rem] tracking-tighter">
            <span className="font-semibold">{header}</span>
            <br />
            <span className="opacity-70">{subheader}</span>
          </h1>
          <Logo className="mt-[1.5rem] size-10 opacity-20" />
        </div>

        {/* Bracket Description */}
        <p
          // biome-ignore lint/security/noDangerouslySetInnerHtml: description is set dynamically from db
          dangerouslySetInnerHTML={{ __html: description ?? "" }}
          className=" mt-[1rem] max-w-[85%] text-[1rem] leading-tight"
        />

        {startDate && <CountdownClock className="mt-4" startDate={startDate} />}

        {/* {["pregame", "entry"].includes(state) && <PregameChecklist className="mt-8" />} */}

        {prizePoolInfo && (
          <PrizePoolBlock className="mt-8 text-[.9375rem]" percentage={poolPct} infoHTML={prizePoolInfo} />
        )}

        {timelineFAQs && <TimelineBlock className="mt-8" timelineFAQs={timelineFAQs} />}

        <h3 className="-mb-1 mt-8 font-semibold text-[1.25rem]">FAQs</h3>
        <FAQs faqs={questions} />

        <div className="mt-6 flex justify-center">
          <p className="max-w-[83%] text-center text-[.9375rem] leading-tight">
            To learn more visit{" "}
            <a
              href="https://github.com/nilli-team/bracket-game-docs/blob/master/Bracket%20Game%20Spec.md"
              target="_blank"
              rel="noopener noreferrer"
              // To do: Add this as a new style component
              className="text-white/75 transition active:opacity-70"
            >
              Bracket Docs ↗{" "}
            </a>
          </p>
        </div>

        <Logo className="mx-auto mt-5 mb-11 size-11" />
      </ScrollArea>
    </DrawerContent>
  )
}
