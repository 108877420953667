import { calculateTimeProgress } from "@web/components/contract/Banner"
import { cn } from "@web/lib/utils"
import type { Contract } from "@web/types"

interface ProgressBarProps {
  contract: Contract
  className?: string
}

export function ProgressBar({ contract, className }: ProgressBarProps) {
  const { state, startDate, endDate } = contract ?? {}
  const timeProgress = calculateTimeProgress(startDate, endDate)

  return (
    <div
      className={cn(
        "-translate-x-1/2 -bottom-[.35rem] -skew-x-12 absolute left-1/2 h-[.2rem] w-[65%] rounded-[.04rem] text-black",
        { "bg-blue-light": ["pregame", "entry", "active", "paused"].includes(state) },
        { "bg-blue-primary": state === "verifying" },
        { "bg-green-secondary": state === "postgame" },
        { "bg-blue-dark": state === "finished" },
        className,
      )}
    >
      {/* Secondary progress bar is drawn first */}
      <div
        className={cn("absolute h-full w-full rounded-[.04rem]", {
          "z-10 bg-[#527AFE]/30": state === "active",
        })}
        style={{ width: `${timeProgress + 1}%` }}
      />

      {/* Primary progress bar overlaps the secondary */}
      <div
        className={cn("absolute h-full rounded-[.04rem]", {
          hidden: timeProgress === 0,
          "z-20 bg-blue-primary": state === "active",
        })}
        style={{ width: `${timeProgress}%` }}
      />
    </div>
  )
}
