import { Link, createFileRoute } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import { MatchupTable } from "@web/components/tables/MatchupTable"
import { contractsQueryOptions, useContracts } from "@web/hooks/queries/useContracts"
import { matchupsQueryOptions, useMatchups } from "@web/hooks/queries/useMatchups"

export const Route = createFileRoute("/$contractSlug/matchups")({
  component: MatchupsRoute,
  loader: async ({ params, context: { queryClient } }) => {
    const { contractSlug } = params
    const [contract] = await queryClient.fetchQuery(contractsQueryOptions(queryClient, contractSlug))
    queryClient.ensureQueryData(matchupsQueryOptions(contract?.matchupContractSlug))
  },
})

function MatchupsRoute() {
  const { contractSlug } = Route.useParams()
  const {
    data: [contract] = [],
  } = useContracts(contractSlug)
  const { data: matchups } = useMatchups(contract?.matchupContractSlug)

  if (!contract || !matchups || matchups.length === 0) {
    return (
      <>
        <div className="flex h-24 items-center justify-center p-4 text-gray-500 text-sm">
          No matchups running, check back later!
        </div>
        <Link to="/" className="pt-[1.2rem] pb-[6rem]">
          <Logo className="mx-auto h-[1.75rem] cursor-pointer text-blue-brand transition-all active:scale-[97%]" />
        </Link>
      </>
    )
  }

  return (
    <>
      {matchups.map((matchup, index) => (
        <div key={matchup.matchupId}>
          <MatchupTable matchup={matchup} showTableEnd={index === matchups.length - 1} />
          {index < matchups.length - 1 && <div className="mx-0 my-5 h-[2px] rounded-full bg-black/10" />}
        </div>
      ))}
    </>
  )
}
