import { Link, useNavigate } from "@tanstack/react-router"
import { Avatar } from "@web/components/shared/Avatar"
import { Chip } from "@web/components/shared/Chip"
import { CollectiveAvatar } from "@web/components/shared/CollectiveAvatar"
import { Username } from "@web/components/shared/Username"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { BLOCK_EXPLORER_URL } from "@web/lib/constants"
import dayjs from "@web/lib/dayjs"
import { formatPriceString } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import type { Activity } from "@web/types"
import { ChevronRight } from "lucide-react"
import type { Address } from "viem"

interface ActivityItemProps {
  activity: Activity
  type: "fan" | "collective" | "contract"
}

const events = {
  explorer: "activity_item:explorer_link_click",
}

export function ActivityItem({ activity, type }: ActivityItemProps) {
  const analytics = useAnalytics(events)
  const { closeModal } = useModal()
  const navigate = useNavigate()

  const {
    eventType,
    fanId,
    fanImageSrc,
    hash,
    provider,
    timestamp,
    tradePrice,
    username,
    voteAmount,
    fanbase,
    primaryColor,
    contractSlug,
    collectiveSlug,
    decimals,
  } = activity
  const formattedTimestamp = dayjs.unix(timestamp).fromNow()

  // Activity labels
  const label = {
    buy: "Buy",
    sell: "Sell",
    redeem: "Win",
    transfer: "Entry",
  }[eventType]

  function handleClickFanbase(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    navigate({ to: `/${contractSlug}/${collectiveSlug}` })
    closeModal()
  }

  if (type === "collective" && (!fanId || !username)) return null

  return (
    <div className="flex w-full items-center justify-between border-gray-border border-b px-4 py-[.83rem]">
      {/* Left half */}
      <Link
        to={type === "collective" || type === "contract" ? `/${contractSlug}/${collectiveSlug}` : ""}
        className="flex items-center justify-start font-medium text-[.875rem]"
      >
        <span
          className={cn(
            "w-[1.9rem] flex-none",
            { "mr-[.4rem]": label === "Entry" },
            { "mr-[.05rem]": label === "Sell" },
            { "mr-[.05rem]": label === "Buy" },
            { "mr-[.13rem]": label === "Prize" },
            { "text-blue-primary": eventType === "buy" },
            { "text-red-primary": eventType === "sell" },
            { "text-green-secondary": eventType === "redeem" },
            { "text-blue-primary/60": eventType === "transfer" },
          )}
        >
          {label}
        </span>

        {/* Collective activity */}
        {type === "collective" && fanId && username && provider && (
          <>
            <Avatar
              src={fanImageSrc}
              alt={`Avatar for ${username}`}
              className="ml-[.4rem] size-6 border-[.075rem] border-gray-light-gray"
            />
            <Username
              fanId={fanId as Address}
              username={username}
              provider={provider}
              className={cn("ml-2", { "opacity-50": eventType === "sell" })}
            />
          </>
        )}

        {/* Fan activity */}
        {type === "fan" && (
          <>
            <CollectiveAvatar
              collective={activity}
              size="small"
              className={cn(
                "ml-[.1rem]",
                { "ml-[.81rem]": eventType === "TransferVotes", "ml-[.25rem]": eventType === "Trade" },
                { "ml-[.62rem]": eventType === "Redeem" },
              )}
            />
            <button
              type="button"
              onClick={handleClickFanbase}
              className="ml-2 cursor-pointer truncate py-1 font-semibold text-[.9375rem] text-black"
            >
              {fanbase}
            </button>
          </>
        )}

        {/* Contract activity */}
        {type === "contract" && username && provider && (
          <>
            <CollectiveAvatar
              collective={activity}
              size="small"
              className={cn(
                "ml-[.28rem]",
                {
                  "ml-[.81rem]": eventType === "TransferVotes",
                  "ml-[.62rem]": eventType === "Redeem",
                  "ml-[.25rem]": eventType === "Trade",
                },
                "flex-none",
              )}
            />
            <Username
              fanId={fanId as Address}
              username={username}
              provider={provider}
              className={cn("ml-[.62rem]", { "opacity-50": eventType === "sell" })}
            />
          </>
        )}

        {/* Only shown for redeems */}
        {eventType === "redeem" && (
          <span className="mt-[.1rem] ml-[.25rem] text-green-secondary italic">
            +{formatPriceString(tradePrice, 2, decimals)}
          </span>
        )}

        {/* Default chip */}
        {eventType !== "redeem" && (
          <Chip
            color={primaryColor}
            className={cn("ml-[.42rem] flex-none scale-[93%] text-sm", {
              "opacity-60": eventType === "sell",
            })}
          >
            {eventType === "sell" ? "-" : "+"}
            {voteAmount}
          </Chip>
        )}
      </Link>

      {/* Right half */}
      <a
        href={`${BLOCK_EXPLORER_URL}/tx/${hash}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => analytics.explorer()}
        className="-mr-[.22rem] flex flex-none items-center justify-end text-gray-link transition-all active:opacity-35"
      >
        <div className="flex items-center ">
          <span className="ml-2 text-sm">{formattedTimestamp}</span>
          <ChevronRight className="mt-[.13rem] size-[1.1rem] opacity-50 transition-opacity duration-300" />
        </div>
      </a>
    </div>
  )
}
