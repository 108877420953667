import { type QueryClient, queryOptions, useQuery, useQueryClient } from "@tanstack/react-query"
import { client } from "@web/lib/hono"
import type { Token } from "@web/types"

async function getTokens() {
  const res = await client.api.tokens.$get()
  return await res.json()
}

async function getToken(tokenSlug: string) {
  const res = await client.api.tokens[":tokenSlug"].$get({ param: { tokenSlug } })
  return await res.json()
}

export function tokensQueryOptions(queryClient: QueryClient, tokenSlug?: string) {
  return queryOptions({
    queryKey: ["tokens", tokenSlug],
    queryFn: () => (tokenSlug ? getToken(tokenSlug) : getTokens()),
    initialData: () => {
      if (!tokenSlug) return
      const token = (queryClient.getQueryData(["tokens"]) as Token[])?.find((t: Token) => t.tokenSlug === tokenSlug)
      return token ? [token] : undefined
    },
  })
}

export function useTokens(tokenSlug?: string) {
  const queryClient = useQueryClient()
  return useQuery(tokensQueryOptions(queryClient, tokenSlug))
}
