import { ButtonController } from "@web/components/buttons/ButtonController"
import { TabBar } from "@web/components/layout/TabBar"
import { cn } from "@web/lib/utils"
import { useEffect, useState } from "react"

export function Footer() {
  const [isVisible, setIsVisible] = useState(true)

  // Hide the tab bar when the user scrolls down
  useEffect(() => {
    let lastScrollTop = 0
    let scrollDelta = 0
    const scrollThreshold = 30

    const handleScroll = () => {
      const currentScroll = window.scrollY
      scrollDelta += currentScroll - lastScrollTop

      if (Math.abs(scrollDelta) >= scrollThreshold) {
        if (scrollDelta > 0) {
          setIsVisible(false)
        } else {
          setIsVisible(true)
        }
        scrollDelta = 0
      }

      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <footer
      className={cn(
        "fixed right-0 bottom-0 left-0 z-20 flex flex-col items-center transition-all duration-300 ease-in-out",
        { "translate-y-0": isVisible, "translate-y-[3.75rem]": !isVisible },
      )}
    >
      <ButtonController />
      <TabBar />
    </footer>
  )
}
