import Spinner from "@web/assets/spinner.svg?react"
import { useTokens } from "@web/hooks/queries/useTokens"
import { formatRawPrice } from "@web/lib/formatters"

interface VotePriceProps {
  prices: Partial<{
    base: bigint
    protocol: bigint
    collective: bigint
    pool: bigint
    slippage: bigint
    total: bigint
  }>
  amount: number
  tokenSlug: string
  isBuy?: boolean
  isLoading: boolean
  type: "trade" | "redeem"
}

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: needs refactor
export function VotePrice({ prices, amount, tokenSlug, isBuy = true, isLoading, type }: VotePriceProps) {
  const {
    data: [token] = [],
  } = useTokens(tokenSlug)

  const { tokenImageSrc, ticker, decimals } = token ?? {}

  const rawPrice = type === "trade" && isBuy && amount === 1 ? prices.base ?? 0n : prices.total ?? 0n
  const price = formatRawPrice(rawPrice, decimals)
  const formattedFees = formatRawPrice(
    (prices.pool ?? 0n) + (prices.collective ?? 0n) + (prices.protocol ?? 0n) + (prices.slippage ?? 0n),
    decimals,
  )

  // if (!tokenImageSrc) {
  //   return (
  //     <div className="flex h-[3.75rem] w-[2.5rem] items-start justify-center">
  //       <Spinner
  //         className={`h-[2rem] w-[2rem] animate-spin-fast ${isBuy ? "text-blue-primary" : "text-red-primary"}`}
  //       />
  //     </div>
  //   )
  // }

  return (
    <>
      {/* Price refreshing */}
      {isLoading && (
        <div className="flex h-[2.5rem] w-[2.5rem] items-center justify-center">
          <Spinner
            className={`h-[2rem] w-[2rem] animate-spin-fast ${isBuy ? "text-blue-primary" : "text-red-primary"}`}
          />
        </div>
      )}

      {/* Big Vote Price */}
      {!isLoading && (
        <div className="relative flex items-start text-4xl">
          {/* Token symbol */}
          {tokenImageSrc && (
            <img
              src={tokenImageSrc ?? ""}
              alt={`Symbol for ${ticker}`}
              className="-left-[1.35rem] absolute top-[.39rem] size-[1.175rem] rounded-full align-top text-white opacity-90"
            />
          )}
          <span className={`-ml-3 font-semibold ${price === "0.0" ? "text-gray-primary" : ""}`}>{price}</span>

          <span className="absolute top-1 right-0 translate-x-full pl-[.22rem] text-gray-primary text-xs">
            {ticker}
          </span>
        </div>
      )}

      {/* Fees explanation */}
      {type === "trade" && (
        <span className="mt-[.25rem] mb-[.1rem] text-gray-primary text-sm">
          {isBuy && amount === 0 && "0.00 for fees and slippage"}
          {isBuy && amount === 1 && `+${formattedFees} for fees and slippage`}
          {isBuy && amount > 1 && "Max amount (including fees)"}
          {!isBuy && "or best available market price"}
        </span>
      )}

      {/* Redeem explanation */}
      {type === "redeem" && (
        <span className="mt-2 mb-1 text-[#242424]/60 text-sm">You will be credited this amount</span>
      )}
    </>
  )
}
