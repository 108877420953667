import { TransparentBox } from "@web/components/faqs/TransparentBox"

interface PrizePoolBlockProps {
  percentage: number
  infoHTML: string
  className?: string
}

export function PrizePoolBlock({ infoHTML, className }: PrizePoolBlockProps) {
  return (
    <div className={className}>
      <div className="mb-1 flex items-baseline justify-between">
        <h1 className="font-semibold text-[1.25rem]">PRIZE POOL</h1>
        {/* <p className="mr-2 opacity-80">{percentage / 100}% of transactions</p> */}
        {/* <p className="mr-2 italic opacity-80">Top 10 Split</p> */}
      </div>
      <TransparentBox className="w-full" innerClassName="px-[1.2rem] py-[.9rem]">
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: prize pool info is set dynamically */}
        <span dangerouslySetInnerHTML={{ __html: infoHTML }} />
      </TransparentBox>
    </div>
  )
}
