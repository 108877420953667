import { useEffect, useRef, useState } from "react"

interface ScrollingTextProps {
  text: string
  className?: string
}

export function ScrollingText({ text, className }: ScrollingTextProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [scrollAmount, setScrollAmount] = useState<number>(0)
  const animationNameRef = useRef(`scrollText-${Math.random().toString(36).substring(7)}`)

  useEffect(() => {
    const contentWidth = contentRef.current?.offsetWidth
    const containerWidth = containerRef.current?.offsetWidth
    if (!contentWidth || !containerWidth) return
    setScrollAmount(contentWidth - containerWidth)

    if (contentWidth > containerWidth * 2) {
      const scrollDuration = 40000
      contentRef.current.style.animation = `${animationNameRef.current} ${scrollDuration}ms linear infinite`
    } else if (contentWidth > containerWidth) {
      const scrollDuration = 10000
      contentRef.current.style.animation = `${animationNameRef.current} ${scrollDuration}ms linear infinite`
    } else {
      contentRef.current.style.animation = "none"
    }
  }, [text])

  return (
    <div
      ref={containerRef}
      style={{ overflowX: "hidden", whiteSpace: "nowrap", position: "relative" }}
      className={`${className}${scrollAmount > 0 ? "fade-x" : ""}`}
    >
      <div ref={contentRef} style={{ display: "inline-block" }}>
        {text}
      </div>
      <div className="-right-2 absolute top-0 h-full w-6 bg-gradient-to-r from-transparent to-white" />
      <style>{`
        @keyframes ${animationNameRef.current} {
          0% {
            transform: translateX(0);
          }
          30% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(-${scrollAmount}px);
          }
          80% {
            transform: translateX(-${scrollAmount}px);
          }
          100% {
            transform: translateX(0);
          }
        }
      `}</style>
    </div>
  )
}
