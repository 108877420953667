import { Link, useLocation, useParams } from "@tanstack/react-router"
import { ProgressBar } from "@web/components/contract/ProgressBar"
import { Loading } from "@web/components/shared/Loading"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useToast } from "@web/hooks/useToast"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { cn } from "@web/lib/utils"

const upcomingContracts = [
  {
    name: "NCAAF '25",
    message: "Stay tuned for College Football Brackets in October 🚀",
  },
]

interface ContractTabsProps {
  className?: string
}

export function ContractTabs({ className }: ContractTabsProps) {
  const { showInfo } = useToast()
  const location = useLocation()

  const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
  const { data: contracts, isLoading } = useContracts()

  if (isLoading || contracts.length === 0) return <Loading />

  return (
    <div className={cn("h-10 cursor-pointer justify-start overflow-x-scroll whitespace-nowrap", className)}>
      {contracts.map((contract) => (
        <Link
          key={contract.contractId}
          to={contract.contractSlug === "nfl25" ? "/$contractSlug/matchups" : "/$contractSlug"} // TODO: Remove this hack
          params={{ contractSlug: contract.contractSlug }}
          className="relative ml-2 inline-flex cursor-pointer justify-center text-nowrap px-2 font-semibold italic transition duration-200 ease-in-out active:scale-[99%] active:text-black"
          activeProps={{ className: "text-black" }}
          inactiveProps={{
            className:
              contract.contractSlug === DEFAULT_CONTRACT && location.pathname === "/" ? "text-black" : "text-gray-link",
          }} // TODO: remove this hack
        >
          {contract.name?.toUpperCase()}
          {contract.contractSlug === contractSlug && <ProgressBar contract={contract} />}
        </Link>
      ))}

      {/* TODO: Remove this once we have more contracts */}
      {upcomingContracts.map(({ name, message }) => (
        <button
          key={name}
          type="button"
          onClick={() => showInfo(message)}
          className="ml-2 cursor-pointer text-nowrap px-2 font-semibold text-gray-link/60 italic"
        >
          {name}
        </button>
      ))}
    </div>
  )
}
