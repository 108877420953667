import { useDynamicContext, useSocialAccounts } from "@dynamic-labs/sdk-react-core"
import { ProviderEnum } from "@dynamic-labs/types"
import FarcasterIcon from "@web/assets/farcaster.svg?react"
import TwitterIcon from "@web/assets/twitter.svg?react"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerContent, DrawerTitle } from "@web/components/ui/drawer"
import { useSocialMutations } from "@web/hooks/mutations/useSocialMutations"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useToast } from "@web/hooks/useToast"
import { ChevronRight } from "lucide-react"
import { useEffect, useRef } from "react"

const events = {
  link: "social_modal:link_button_click",
  later: "social_modal:later_button_click",
}

export default function SocialModal() {
  const analytics = useAnalytics(events)
  // const { openModal } = useModal()
  const { showInfo, showError } = useToast()
  const { fanId, provider: currentProvider, refetchFan } = useLoginContext()

  const { connectSocial } = useSocialMutations()
  const { showDynamicUserProfile, setShowDynamicUserProfile } = useDynamicContext()
  const { linkSocialAccount, isLinked } = useSocialAccounts()

  const isTwitterLinked = isLinked(ProviderEnum.Twitter)
  const isFarcasterLinked = isLinked(ProviderEnum.Farcaster)
  const prevTwitterLinkedRef = useRef(isTwitterLinked)
  const prevFarcasterLinkedRef = useRef(isFarcasterLinked)

  // function handleClickLater() {
  //   analytics.later()
  //   openModal(Modal.Profile)
  // }

  async function changeSocial(provider: ProviderEnum) {
    try {
      const newSocial = await connectSocial({ provider })
      if (!newSocial || !refetchFan) {
        showError(`Failed to link ${provider} account`)
        return
      }
      refetchFan()
      showInfo(`Successfully linked ${provider} account`)
    } catch (error) {
      console.error(error)
      showError(`Error linking ${provider} account`)
    }
  }

  async function handleClickSocial(provider: ProviderEnum) {
    analytics.link({ provider })

    if (isLinked(provider) && provider === currentProvider) {
      showInfo("You've already linked this account")
      return
    }

    if (isLinked(provider) && provider !== currentProvider) {
      await changeSocial(provider)
      return
    }

    await linkSocialAccount(provider)
  }

  // Listen to social link events to connect and refetch
  useEffect(() => {
    async function updateSocial() {
      if (!prevTwitterLinkedRef.current && isTwitterLinked) {
        await changeSocial(ProviderEnum.Twitter)
      }
      prevTwitterLinkedRef.current = isTwitterLinked

      if (!prevFarcasterLinkedRef.current && isFarcasterLinked) {
        await changeSocial(ProviderEnum.Farcaster)
      }
      prevFarcasterLinkedRef.current = isFarcasterLinked
    }
    updateSocial()
  }, [isTwitterLinked, isFarcasterLinked])

  // Auto-close dynamic user model if opened
  useEffect(() => {
    if (showDynamicUserProfile) setShowDynamicUserProfile(false)
  }, [showDynamicUserProfile])

  if (!fanId) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      {/* <Handle /> */}
      <DrawerTitle className="mt-8">Claim your Identity</DrawerTitle>

      <div className="flex flex-col items-center justify-center px-20">
        <p className="mt-4 mb-6 text-center text-gray-primary leading-[1.33rem]">
          Connect your social accounts to link your identity to your Bracket profile.
        </p>

        <Button
          disabled={currentProvider === "farcaster"}
          onClick={() => handleClickSocial(ProviderEnum.Farcaster)}
          className="h-[3.25rem] w-[21rem] bg-purple-farcaster text-[1.0625rem] text-white active:bg-purple-farcaster active:opacity-80 disabled:opacity-70"
        >
          <div className="relative ml-2 flex w-full items-center justify-between">
            <div className="flex flex-1 items-center justify-center">
              <FarcasterIcon className="mr-2 size-[1.1rem]" />
              <span>{currentProvider === "farcaster" ? "Connected" : "Connect with Farcaster"}</span>
            </div>
            {currentProvider !== "farcaster" && <ChevronRight className="-mr-1 size-6 text-white" />}
          </div>
        </Button>

        <Button
          disabled={currentProvider === "twitter"}
          onClick={() => handleClickSocial(ProviderEnum.Twitter)}
          className="mt-2 mb-8 h-[3.25rem] w-[21rem] bg-black text-[1.0625rem] text-white active:bg-black active:opacity-80 disabled:opacity-70"
        >
          <div className="relative ml-2 flex w-full items-center justify-between">
            <div className="flex flex-1 items-center justify-center">
              <TwitterIcon className="mr-2 size-[1.1rem]" />
              <span>{currentProvider === "twitter" ? "Connected" : "Connect with X"}</span>
            </div>
            {currentProvider !== "twitter" && <ChevronRight className="-mr-1 size-6 text-white" />}
          </div>
        </Button>

        {/* <Button variant="link" onClick={handleClickLater} className="mt-2 mb-3 text-gray-primary">
          Skip for now
        </Button> */}
      </div>
    </DrawerContent>
  )
}
