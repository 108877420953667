import { useMutation } from "@tanstack/react-query"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useToast } from "@web/hooks/useToast"
import { client } from "@web/lib/hono"
import type { InferRequestType, InferResponseType } from "hono"

const events = {
  enter: "entry:enter_bracket",
}

export function useEntryMutations() {
  const analytics = useAnalytics(events)
  const { showError } = useToast()

  const $post = client.api.fan.entry.$post

  const mutation = useMutation<InferResponseType<typeof $post>, Error, InferRequestType<typeof $post>["json"]>({
    mutationFn: async ({ contractSlug }) => {
      if (!contractSlug) throw new Error("Contract slug is required")
      analytics.enter({ contractSlug })

      const res = await $post({ json: { contractSlug } })
      if (!res.ok) {
        throw new Error(`Failed to enter contract: ${res.status}`)
      }
      return res.json()
    },
    onError: (error) => {
      showError("Failed to enter contract", error)
    },
  })

  return { createEntry: mutation.mutateAsync }
}
