import { chains } from "@web/lib/constants"
import { createPublicClient } from "viem"
import { http, createConfig, fallback } from "wagmi"

const chain = chains[import.meta.env.VITE_PRIMARY_CHAIN]
const transports = {
  [chain.id]: fallback([http(import.meta.env.VITE_PRIMARY_RPC_URL), http(import.meta.env.VITE_FALLBACK_RPC_URL)]),
}

export const config = createConfig({
  chains: [chains[import.meta.env.VITE_PRIMARY_CHAIN]],
  multiInjectedProviderDiscovery: false,
  // @ts-expect-error NOTE: Chain union type in constants throws error
  transports,
})

export const publicClient = createPublicClient({
  chain,
  transport: transports[chain.id],
})
