import type { ColumnDef } from "@tanstack/react-table"
import { OwnerChip } from "@web/components/contract/OwnerChip"
import { Chip } from "@web/components/shared/Chip"
import { CollectiveCell } from "@web/components/tables/CollectiveCell"
import { ColumnHeader } from "@web/components/tables/ColumnHeader"
import { PriceCell } from "@web/components/tables/PriceCell"
import type { Collective } from "@web/types"

export const collectiveColumns: ColumnDef<Collective>[] = [
  {
    accessorKey: "fanbase",
    size: 65,
    header: (header) => {
      // @ts-ignore
      const { isMatchup, matchupTitle, disableSorting } = header.table.options.meta ?? {}

      return (
        <ColumnHeader column={header.column} className="ml-[.8rem] w-fit" disableSorting={disableSorting}>
          {isMatchup ? matchupTitle : "Collective"}
        </ColumnHeader>
      )
    },
    cell: ({ row }) => {
      return <CollectiveCell collective={row.original} />
    },
  },
  {
    id: "fanCountOrVotes",
    accessorFn: (row) => row.fanCount ?? row.fanVotes,
    size: 15,
    header: (header) => {
      // @ts-ignore
      const { disableSorting, isHoldings } = header.table.options.meta ?? {}

      return (
        <ColumnHeader
          column={header.column}
          position="right"
          className="-mr-[.0rem] w-fit"
          disableSorting={disableSorting}
        >
          {isHoldings ? "HOLDING" : "OWNERS"}
        </ColumnHeader>
      )
    },
    cell: (cell) => {
      // @ts-ignore
      if (cell.table.options.meta.isHoldings) {
        const { fanVotes, primaryColor } = cell.row.original
        return (
          <div className="float-right mr-2.5 flex h-full items-center text-right">
            <Chip color={primaryColor} className="my-3 inline-block">
              {fanVotes}
            </Chip>
          </div>
        )
      }

      return <OwnerChip collective={cell.row.original} className="mr-3" />
    },
  },
  {
    accessorKey: "price",
    size: 20,
    header: (header) => {
      // @ts-ignore
      const { token, disableSorting } = header.table.options.meta ?? {}

      return (
        <ColumnHeader
          column={header.column}
          position="right"
          className="mx-auto mr-[.5rem] w-fit"
          disableSorting={disableSorting}
        >
          {token ? `$${token.toUpperCase()}` : "Price"}
        </ColumnHeader>
      )
    },
    cell: (cell) => {
      // @ts-ignore
      const { isHoldings } = cell.table.options.meta ?? {}
      const { collectivePrice, state, decimals, updatedAt, isActive, averageBuyPrice } = cell.row.original
      return (
        <PriceCell
          averageBuyPrice={isHoldings ? averageBuyPrice : undefined}
          decimals={decimals}
          isActive={isActive}
          price={collectivePrice}
          state={state}
          updatedAt={updatedAt}
        />
      )
    },
    sortingFn: "alphanumeric",
  },
  // {
  //   accessorKey: "percent",
  //   size: 0,
  //   header: () => null,
  //   cell: () => null,
  //   sortingFn: (a, b) => {
  //     const percentA = 0 // a.original.percent
  //     const percentB = 0 //b.original.percent

  //     // Place zeros at the end
  //     if (percentA === 0 && percentB !== 0) return -1
  //     if (percentB === 0 && percentA !== 0) return 1
  //     // Normal comparison for non-zero values
  //     if (percentA > percentB) return 1
  //     if (percentA < percentB) return -1
  //     return 0
  //   },
  // },
]
