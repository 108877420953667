import { queryOptions, useSuspenseQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"

async function getMatchups(contractSlug: string) {
  const res = await client.api.contracts[":contractSlug"].matchups.$get({ param: { contractSlug } })
  return await res.json()
}

export function matchupsQueryOptions(contractSlug: string) {
  return queryOptions({
    queryKey: ["matchups", contractSlug],
    queryFn: () => getMatchups(contractSlug),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useMatchups(contractSlug: string) {
  return useSuspenseQuery(matchupsQueryOptions(contractSlug))
}
