import { useTokens } from "@web/hooks/queries/useTokens"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { checkReceipt } from "@web/lib/trade"
import { publicClient } from "@web/lib/viem"
import { useEffect, useReducer } from "react"
import { type Address, erc20Abi, isAddress, parseUnits } from "viem"
import { useSendTransaction, useWriteContract } from "wagmi"

type State = {
  status: "ready" | "pending" | "success" | "error"
  amount: number
  balance: number
  hasConfirmed: boolean
  hash?: `0x${string}`
  error?: string
}

type Action =
  | { type: "updated"; amount?: number; balance?: number; hasConfirmed?: boolean }
  | { type: "executed" }
  | { type: "succeeded"; hash: `0x${string}` }
  | { type: "failed"; error?: string }
  | { type: "reset" }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "updated":
      return {
        ...state,
        status: "ready",
        amount: action.amount ?? state.amount,
        balance: action.balance ?? state.balance,
        hasConfirmed: action.hasConfirmed ?? state.hasConfirmed,
      }
    case "executed":
      return { ...state, status: "pending" }
    case "succeeded":
      return { ...state, status: "success", hash: action.hash }
    case "failed":
      return { ...state, status: "error", error: action.error }
    case "reset":
      return { ...init({}) }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

function init(initialState: Partial<State>): State {
  return {
    status: "ready",
    amount: initialState.amount ?? 0,
    balance: initialState.balance ?? 0,
    hasConfirmed: initialState.hasConfirmed ?? false,
  }
}

function validate(
  amount: number,
  balance: number,
  hasConfirmed?: boolean,
  fan?: `0x${string}`,
  target?: `0x${string}`,
) {
  if (!fan) return "Please login to continue"
  if (!target) return "Please enter a wallet address"
  if (!hasConfirmed) return "Please confirm the address is correct"
  if (!isAddress(target)) return "Please enter a valid wallet address"
  if (amount <= 0) return "Please enter a valid amount"
  if (amount > balance) return "Insufficient balance"
  // TODO: add more cases

  return undefined
}

export function useWithdrawToken({
  tokenSlug,
  target,
  amount,
  balance,
  hasConfirmed,
}: {
  tokenSlug: string
  target?: `0x${string}`
  amount?: number
  balance?: number
  hasConfirmed?: boolean
}) {
  const { fanId } = useLoginContext()

  const {
    data: [token] = [],
  } = useTokens(tokenSlug)
  const { decimals, tokenId } = token ?? {}
  const [state, dispatch] = useReducer(reducer, { amount, balance, hasConfirmed }, init)

  const validationError = validate(state.amount, state.balance, hasConfirmed, fanId, target)
  const { writeContractAsync, error } = useWriteContract()
  const { sendTransactionAsync } = useSendTransaction()

  function onInputChange(value: string) {
    // TODO: add debouncing
    if (!tokenId) return
    if (!value) return dispatch({ type: "updated", amount: 0 })
    dispatch({ type: "updated", amount: Number(value) })
  }

  async function execute() {
    if (!target || !tokenId) return
    if (state.status !== "ready") return
    dispatch({ type: "executed" })

    const amount = parseUnits(state.amount.toString(), decimals)

    let hash: `0x${string}` | undefined
    if (tokenSlug === "eth") {
      hash = await sendTransactionAsync({
        to: target,
        value: amount,
      })
    } else {
      hash = await writeContractAsync({
        address: tokenId,
        abi: erc20Abi,
        functionName: "transfer",
        args: [target, amount],
      })
    }
    const error = await checkReceipt(hash, fanId as Address, publicClient)
    if (error) {
      dispatch({ type: "failed", error })
      return { error }
    }

    dispatch({ type: "succeeded", hash })
    return { amount: state.amount }
  }

  // Validate the transaction before proceeding
  useEffect(() => {
    if (validationError) dispatch({ type: "failed", error: validationError })
  }, [validationError])

  // Failed transactions produce an error
  useEffect(() => {
    if (error) dispatch({ type: "failed", error: error.message })
  }, [error])

  // Watch the user's token balance
  useEffect(() => {
    if (balance) dispatch({ type: "updated", balance })
  }, [balance])

  // Check for readiness if the wallet address is confirmed
  useEffect(() => {
    if (hasConfirmed) dispatch({ type: "updated", hasConfirmed })
  }, [hasConfirmed])

  return {
    state,
    execute,
    reset: () => dispatch({ type: "reset" }),
    onInputChange,
  }
}
