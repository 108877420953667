import Logo from "@web/assets/logo.svg?react"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerContent, DrawerFooter, DrawerHeader } from "@web/components/ui/drawer"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"

const events = {
  withdraw: "offboard_modal:withdraw_button_click",
  cancel: "offboard_modal:cancel_button_click",
  faqs: "offboard_modal:faqs_button_click",
}

export default function OffboardModal() {
  const analytics = useAnalytics(events)
  const { openModal, closeModal } = useModal()
  const { isLoggedIn } = useLoginContext()

  function handleClickWithdraw() {
    analytics.withdraw()
    openModal(Modal.Withdraw)
  }

  function handleClickCancel() {
    analytics.cancel()
    closeModal()
  }

  function handleClickFAQs() {
    analytics.faqs()
    openModal(Modal.FAQs)
  }

  if (!isLoggedIn) {
    return (
      <DrawerContent className="min-h-72">
        <Loading />
      </DrawerContent>
    )
  }

  return (
    <DrawerContent>
      <div className="-mt-9 scale-105 overflow-hidden rounded-full border-4 border-white">
        <Logo className="mt- size-16 bg-gradient-to-t from-blue-brand to-blue-primary p-3 text-white" />
      </div>

      <DrawerHeader>
        <div className="flex items-center">
          <span className="-mt-[.3rem] mb-[.3rem] font-semibold">Withdraw to external wallet</span>
        </div>
      </DrawerHeader>

      <div className="flex flex-col px-8">
        <p className="mb-[1.4rem] text-center text-gray-primary leading-[1.33rem]">
          To withdraw funds, you need an external wallet that accepts tokens on the {""}
          <a
            href="https://base.org"
            rel="noopener"
            className="font-medium underline underline-offset-[5px]"
            style={{ textDecorationColor: "#E3D9D9" }}
          >
            Base Network
          </a>
          .
        </p>

        <p className="mb-[.9rem] text-center text-gray-primary leading-[1.33rem]">
          If you do not have an existing wallet, we recommend using{" "}
          <a
            href="https://coinbase.com/wallet"
            rel="noopener"
            className="font-medium underline underline-offset-[5px]"
            style={{ textDecorationColor: "#E3D9D9" }}
          >
            Coinbase Wallet
          </a>{" "}
          .
        </p>

        <Button
          className="relative mx-auto mt-[.82rem] h-14 w-full px-7 font-semibold text-base"
          onClick={handleClickWithdraw}
        >
          I&apos;m ready to withdraw
          <ChevronRight className="absolute right-2 size-5 stroke-white" />
        </Button>

        <Button
          variant="outline"
          className="mt-3 h-14 w-full border-blue-secondary px-7 font-semibold text-base text-blue-primary active:border-blue-primary"
          onClick={handleClickCancel}
        >
          Nevermind, maybe later
        </Button>
      </div>

      <DrawerFooter className="my-3 flex-row gap-0 text-center text-gray-dark text-sm">
        <span>To learn more about Bracket.Game, see</span>
        <button
          type="button"
          onClick={handleClickFAQs}
          className="ml-1 font-medium underline decoration-[#E3D9D9] underline-offset-[5px]"
        >
          Bracket 101
        </button>
      </DrawerFooter>
    </DrawerContent>
  )
}
