import { base, baseSepolia } from "wagmi/chains"

// App
export const DEFAULT_POLLING_INTERVAL = 5_000 // 5 seconds

// Contracts
export const DEFAULT_CONTRACT = "p3"
export const DEFAULT_MATCHUP = "mlb24wc"

// Chains
type Chain = typeof base | typeof baseSepolia
export const chains: Record<string, Chain> = {
  base,
  baseSepolia,
}
export const PRIMARY_CHAIN = chains[import.meta.env.VITE_PRIMARY_CHAIN as keyof typeof chains]
export const SLIPPAGE = 5n

// Explorers
const blockExplorers: Record<string, string> = {
  base: "https://basescan.org",
  baseSepolia: "https://sepolia.basescan.org",
}
export const BLOCK_EXPLORER_URL = blockExplorers[import.meta.env.VITE_PRIMARY_CHAIN as string]

// Links
export const TWITTER_URL = "https://twitter.com/bracketHQ"
export const FARCASTER_URL = "https://warpcast.com/~/channel/bracket"
