import Logo from "@web/assets/logo.svg?react"
import { useStats } from "@web/hooks/queries/useStats"
import { useModal } from "@web/hooks/useModal"
import { formatPriceString } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import { Modal } from "@web/types"
import { default as ReactMarquee } from "react-fast-marquee"

interface MarqueeProps {
  contractSlug: string
}

export function Marquee({ contractSlug }: MarqueeProps) {
  const { openModal } = useModal()
  const {
    data: [stats] = [],
    isLoading,
  } = useStats(contractSlug)

  const { displayName, uniqueOwners, volume24h, ticker, decimals, prizePoolOverride } = stats ?? {
    displayName: "Loading...",
    uniqueOwners: 0,
    volume24h: "0.0",
    ticker: "",
    decimals: 18,
  }

  if (isLoading || !stats) {
    return (
      <div className="relative flex h-[2.2rem] w-full items-center overflow-hidden bg-[#738ff7] text-[0.8125rem] text-white transition-all">
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation() // Prevents the FAQ modal from opening
            openModal(Modal.Nav)
          }}
          className="-ml-[1.15rem] -skew-x-12 absolute z-10 flex h-full w-[4.5rem] flex-col items-center justify-center rounded-tr-[.15rem] rounded-br-[.15rem] bg-blue-brand/[.65] pl-[0.1rem] backdrop-blur-[.1rem] transition"
        >
          <Logo className="ml-[1.0rem] h-[1.25rem] skew-x-12 cursor-pointer text-white transition hover:opacity-100 active:scale-95" />
        </button>

        {/* SUBTLE SHADOW */}
        <div className="-mr-[.1rem] -skew-x-12 absolute left-0 z-5 flex h-full w-[3.8rem] flex-col items-center justify-center rounded-tl-[.15rem] rounded-bl-[.15rem] bg-blue-brand/[.05] pl-[0.1rem] blur-sm backdrop-blur-[0rem] transition" />
      </div>
    )
  }

  return (
    <div className="relative flex h-[2.2rem] w-full items-center overflow-hidden bg-[#738ff7] text-[0.8125rem] text-white transition-all">
      <button type="button" onClick={() => openModal(Modal.FAQs)}>
        <ReactMarquee
          speed={50}
          className={cn("uppercase tracking-wide opacity-0 transition-opacity duration-300", {
            "opacity-100": !isLoading,
          })}
        >
          <span className="mx-2 font-semibold">{displayName}</span>

          <span className="mx-2">
            Prize:{" "}
            {prizePoolOverride ? (
              // biome-ignore lint/security/noDangerouslySetInnerHtml: prize pool can be dynamically set from db
              <span dangerouslySetInnerHTML={{ __html: prizePoolOverride }} />
            ) : (
              <strong>TBA</strong>
            )}
          </span>

          <span className="mx-2">
            OWNERS: <strong>{uniqueOwners}</strong>
          </span>

          <span className="mx-2">
            24H VOL: <strong>{formatPriceString(volume24h, 2, decimals)}</strong> {ticker}
          </span>
        </ReactMarquee>
      </button>

      {/* BLUE BACKGROUND */}
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation() // Prevents the FAQ modal from opening
          openModal(Modal.Nav)
        }}
        className="-ml-[1.15rem] -skew-x-12 absolute z-10 flex h-full w-[4.5rem] flex-col items-center justify-center rounded-tr-[.15rem] rounded-br-[.15rem] bg-blue-brand/[.65] pl-[0.1rem] backdrop-blur-[.1rem] transition"
      >
        <Logo className="ml-[1.0rem] h-[1.25rem] skew-x-12 cursor-pointer text-white transition hover:opacity-100 active:scale-95" />
      </button>

      {/* SUBTLE SHADOW */}
      <div className="-mr-[.1rem] -skew-x-12 absolute left-0 z-5 flex h-full w-[3.8rem] flex-col items-center justify-center rounded-tl-[.15rem] rounded-bl-[.15rem] bg-blue-brand/[.05] pl-[0.1rem] blur-sm backdrop-blur-[0rem] transition" />
    </div>
  )
}
