import { Popover, PopoverContent, PopoverTrigger } from "@web/components/ui/popover"
import { Info } from "lucide-react"

export function WinPricePopover() {
  return (
    <Popover>
      <PopoverTrigger className="flex items-center">
        <Info className="mb-[.1rem] ml-[.31rem] inline size-[.9rem] text-gray-primary/80" />
      </PopoverTrigger>

      <PopoverContent className="flex max-w-[17rem] bg-white/80 p-3 backdrop-blur-lg">
        <span className="pl-2 text-left font-regular text-gray-primary text-sm">
          Estimated minimum price per share if collective wins the matchup.
        </span>
      </PopoverContent>
    </Popover>
  )
}
