import { client } from "@web/lib/hono"
import type { InferResponseType } from "hono"

export type Filter = "activity" | "holdings"

export enum Modal {
  Activity = "activity",
  Deposit = "deposit",
  Fan = "fan",
  FAQs = "faqs",
  Fund = "fund",
  Nav = "nav",
  Offboard = "offboard",
  Olympics = "olympics",
  Onboard = "onboard",
  Profile = "profile",
  Redeem = "redeem",
  Social = "social",
  Trade = "trade",
  Withdraw = "withdraw",
  Entry = "entry",
}

// Query types
const getActivity = client.api.contracts[":contractSlug"].activity.$get
type Activities = InferResponseType<typeof getActivity>
export type Activity = Activities[number]

const getAuthedFan = client.api.fan.$get
type AuthedFans = InferResponseType<typeof getAuthedFan>
export type AuthedFan = AuthedFans[number]

const getContracts = client.api.contracts.$get
type Contracts = InferResponseType<typeof getContracts>
export type Contract = Contracts[number]

const getCollectives = client.api.contracts[":contractSlug"].collectives.$get
type Collectives = InferResponseType<typeof getCollectives>
export type Collective = Collectives[number]

const getEntries = client.api.contracts[":contractSlug"].entries.$get
type Entries = InferResponseType<typeof getEntries>
export type Entry = Entries[number]

const getFan = client.api.fan[":fanId"].$get
type Fans = InferResponseType<typeof getFan>
export type Fan = Fans[number]

const getHoldings = client.api.fan[":fanId"].holdings.$get
type Holdings = InferResponseType<typeof getHoldings>
export type Holding = Holdings[number]

const getMatchups = client.api.contracts[":contractSlug"].matchups.$get
type Matchups = InferResponseType<typeof getMatchups>
export type Matchup = Matchups[number]

const getOwners = client.api.contracts[":contractSlug"].owners.$get
type Owners = InferResponseType<typeof getOwners>
export type Owner = Owners[number]

const getTokens = client.api.tokens.$get
type Tokens = InferResponseType<typeof getTokens>
export type Token = Tokens[number]
