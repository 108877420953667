import { queryOptions, useQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"

async function getContractOwners(contractSlug: string) {
  const res = await client.api.contracts[":contractSlug"].owners.$get({ param: { contractSlug } })
  return await res.json()
}

async function getCollectiveOwners(contractSlug: string, collectiveSlug: string) {
  const res = await client.api.contracts[":contractSlug"].collectives[":collectiveSlug"].owners.$get({
    param: { contractSlug, collectiveSlug },
  })
  return await res.json()
}

function ownersQueryOptions(contractSlug: string, collectiveSlug?: string) {
  return queryOptions({
    queryKey: ["owners", contractSlug, collectiveSlug],
    queryFn: () =>
      collectiveSlug ? getCollectiveOwners(contractSlug, collectiveSlug) : getContractOwners(contractSlug),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useOwners(contractSlug: string, collectiveSlug?: string) {
  return useQuery(ownersQueryOptions(contractSlug, collectiveSlug))
}
