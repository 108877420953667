import { queryOptions, useQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"
import type { Address } from "viem"

async function getHoldings(fanId: Address) {
  const res = await client.api.fan[":fanId"].holdings.$get({ param: { fanId } })
  return await res.json()
}

function holdingsQueryOptions(fanId: Address) {
  return queryOptions({
    queryKey: ["holdings", fanId],
    queryFn: () => getHoldings(fanId),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useHoldings(fanId: Address) {
  return useQuery(holdingsQueryOptions(fanId))
}
