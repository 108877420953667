import { ZERODEV_ABI } from "@contract/zerodev"
import { type Client, hexToString, parseEventLogs } from "viem"
import { getTransactionReceipt } from "viem/actions"

function getRevertMessage(reason: string) {
  const messages = [
    "Amount must be greater than 0",
    "Cannot redeem the last vote",
    "Cannot sell the last vote",
    "Cannot transfer the last vote",
    "Insufficient balance",
    "Insufficient votes",
    "More than remaining supply",
    "Only the votes' collective can buy the first vote",
    "Season not initialized",
    "Trading is paused",
    "Transaction cost below minimum amount",
    "Transaction cost exceeds maximum amount",
  ]

  const message = messages.find((message) => reason.includes(message))
  return message ?? "Unknown error"
}

// Check the transaction receipt for reverts
export async function checkReceipt(hash: `0x${string}`, fan: `0x${string}`, client: Client) {
  const receipt = await getTransactionReceipt(client, { hash })
  const logs = parseEventLogs({
    abi: ZERODEV_ABI,
    logs: receipt.logs,
    eventName: "UserOperationRevertReason",
  })

  // Verify revert is for current fan
  for (const log of logs) {
    if (log.args.sender === fan) {
      const reason = hexToString(log.args.revertReason)
      const message = getRevertMessage(reason)
      return message
    }
  }
  return null
}
