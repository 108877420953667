import { type ClassValue, clsx } from "clsx"
import { toast } from "sonner"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function shorten(address: string | null | undefined, length = 4): string {
  if (!address) return ""
  if (address.length < 32) return address
  return `${address.slice(0, 6)}...${address.slice(-length)}`.toLowerCase()
}

export function sharePage(title = "", text = "", overrideUrl?: string) {
  const url = overrideUrl ?? window.location.href
  if (!url) return

  const data = { url, title, text }

  if (navigator.canShare?.(data)) {
    navigator.share(data)
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(url)
    toast.success("Invite link copied")
  } else {
    toast.error("Something went wrong")
  }
}

export function removeLeadingZeros(input: string): string {
  return input.length > 1 ? input.replace(/^0+/, "") : input
}

export function getOrdinalSuffix(n: number): string {
  const s = ["th", "st", "nd", "rd"]
  const v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}

export function hexToRgba(hex: string, opacity: number) {
  // Remove the hash at the start if it's there
  const cleanHex = hex.replace(/^#/, "")

  // Parse r, g, b values
  const bigint = Number.parseInt(cleanHex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function formatVotingPower(percentage: number) {
  const formattedString = percentage >= 1 ? percentage.toFixed(2) : percentage.toFixed(2).replace("0.", ".")
  return formattedString === ".00" ? "<.01" : formattedString
}

export function getVotingPower(
  fanVotes: number,
  voteCount: number,
  burntVoteCount: number,
  claimerVoteCount: number,
  tradeAmount?: number,
) {
  const activeCollectiveShares = voteCount - burntVoteCount - claimerVoteCount - 1 // Subtract one for the collective
  if (!activeCollectiveShares) return 0

  // Override default calculation with a tradeAmount if set
  return ((tradeAmount ?? fanVotes) / (activeCollectiveShares + (tradeAmount ?? 0))) * 100
}

export function calculatePointsPrize(numberOfEntries: number, multiplier = 1) {
  // NOTE: Equation from Adam
  // const result = 629.2 * (numberOfEntries + 1) * multiplier
  // NOTE: Equation from Tim
  const result = (1000 * numberOfEntries * multiplier) / 3

  // Round to the nearest 1000
  return Math.round(result / 1000) * 1000
}

export function calculateEquilibriumPrice(
  numberOfEntries: number,
  multiplier: number,
  curveDenominator: number,
  finalPrizePool = 0,
) {
  const prizePool = finalPrizePool !== 0 ? finalPrizePool : calculatePointsPrize(numberOfEntries, multiplier)
  const equilibriumPrice = Math.sqrt(prizePool * curveDenominator) / curveDenominator
  return equilibriumPrice
}
