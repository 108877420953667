import { cn } from "@web/lib/utils"

interface PercentChangeProps {
  percent: number
  decimals?: number
  showZero?: boolean
  zeroFormat?: string
  isActive?: boolean
  className?: string
}

export function PercentChange({ percent, zeroFormat, decimals = 2, isActive = true, className }: PercentChangeProps) {
  const value = !Number.isNaN(percent) && percent ? percent : 0

  // biome-ignore lint/correctness/noConstantCondition: TODO: fix
  if (true) {
    // (showZero || percent === 0) {
    const zeroText = zeroFormat ?? "0.00%"
    return (
      <span
        className={cn(
          "font-mono",
          { "text-gray-primary/50": !isActive, "text-green-secondary/50": isActive },
          className,
        )}
      >
        {zeroText}
      </span>
    )
  }

  return (
    <span
      className={cn("font-mono", { "text-green-secondary": value > 0 }, { "text-red-primary": value < 0 }, className)}
    >
      {value > 0 && "+"}
      {value.toFixed(Math.abs(value) > 1000 ? 0 : decimals)}%
    </span>
  )
}
