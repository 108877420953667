import { type QueryClient, queryOptions, useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"
import type { Collective } from "@web/types"

async function getCollectives(contractSlug: string) {
  const res = await client.api.contracts[":contractSlug"].collectives.$get({ param: { contractSlug } })
  return await res.json()
}

async function getCollective(contractSlug: string, collectiveSlug: string) {
  const res = await client.api.contracts[":contractSlug"].collectives[":collectiveSlug"].$get({
    param: { contractSlug, collectiveSlug },
  })
  return await res.json()
}

export function collectivesQueryOptions(queryClient: QueryClient, contractSlug: string, collectiveSlug?: string) {
  return queryOptions({
    queryKey: ["collectives", contractSlug, collectiveSlug],
    queryFn: () => (collectiveSlug ? getCollective(contractSlug, collectiveSlug) : getCollectives(contractSlug)),
    initialData: () => {
      if (!collectiveSlug) return
      const collective = (queryClient.getQueryData(["contracts", contractSlug, "collectives"]) as Collective[])?.find(
        (c: Collective) => c.collectiveSlug === collectiveSlug,
      )
      return collective ? [collective] : undefined
    },
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useCollectives(contractSlug: string, collectiveSlug?: string) {
  const queryClient = useQueryClient()
  return useSuspenseQuery(collectivesQueryOptions(queryClient, contractSlug, collectiveSlug))
}
