import { EthereumWalletConnectors } from "@dynamic-labs/ethereum"
import { ZeroDevSmartWalletConnectors } from "@dynamic-labs/ethereum-aa"
import type { OnAuthSuccess, UserProfile } from "@dynamic-labs/sdk-react-core"
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core"
import { useAnalytics } from "@web/hooks/useAnalytics"

const events = {
  authenticate: "dynamic:fan_authenticate",
  logout: "dynamic:fan_logout",
  link: "dynamic:wallet_link",
  unlink: "dynamic:wallet_unlink",
}

interface DynamicProviderProps {
  children: React.ReactNode
}

export function DynamicProvider({ children }: DynamicProviderProps) {
  const analytics = useAnalytics(events)

  function onAuthSuccess(args: Parameters<OnAuthSuccess>[0]) {
    const { user, primaryWallet } = args
    const { email, newUser, username } = user

    analytics.authenticate({
      address: primaryWallet?.address,
      email,
      newUser,
      username,
    })

    // Prompt new users to connect socials, NOTE: modal hooks are not available in this context
    if (newUser) {
      const currentUrl = new URL(window.location.href)
      currentUrl.searchParams.set("modal", "social")
      window.history.pushState({}, "", currentUrl.toString())
    }
  }

  function onLogout(args?: UserProfile) {
    const { email, username, userId } = args ?? {}
    analytics.logout({
      email,
      username,
      userId,
    })
  }

  return (
    <DynamicContextProvider
      settings={{
        environmentId: import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID,
        eventsCallbacks: { onAuthSuccess, onLogout },
        walletConnectors: [EthereumWalletConnectors, ZeroDevSmartWalletConnectors],
      }}
    >
      {children}
    </DynamicContextProvider>
  )
}
