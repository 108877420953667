import { type QueryClient, queryOptions, useQueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"
import type { Contract } from "@web/types"

async function getContracts() {
  const res = await client.api.contracts.$get()
  return await res.json()
}

async function getContract(contractSlug: string) {
  const res = await client.api.contracts[":contractSlug"].$get({ param: { contractSlug } })
  return await res.json()
}

export function contractsQueryOptions(queryClient: QueryClient, contractSlug?: string) {
  return queryOptions({
    queryKey: ["contracts", contractSlug],
    queryFn: () => (contractSlug ? getContract(contractSlug) : getContracts()),
    initialData: () => {
      if (!contractSlug) return
      const contract = (queryClient.getQueryData(["contracts"]) as Contract[])?.find(
        (c: Contract) => c.contractSlug === contractSlug,
      )
      return contract ? [contract] : undefined
    },
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useContracts(contractSlug?: string) {
  const queryClient = useQueryClient()
  return useSuspenseQuery(contractsQueryOptions(queryClient, contractSlug))
}
