import * as Sentry from "@sentry/react"
import type React from "react"
import { Component, type ReactNode } from "react"

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo)
    Sentry.captureException(error, {
      extra: {
        errorInfo,
      },
    })

    // Hide the normal splash screen and show the error splash screen
    const splashElement = document.getElementById("splash")
    const errorSplashElement = document.getElementById("error-splash")
    if (splashElement) splashElement.style.display = "none"
    if (errorSplashElement) errorSplashElement.style.display = "flex"
  }

  render() {
    if (this.state.hasError) return null

    return this.props.children
  }
}

export default ErrorBoundary
