import { BRACKET_ABI } from "@contract/bracket"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import type { Address } from "viem"
import { useReadContracts } from "wagmi"

/**
 * This hooks returns the real-time vote price of a collective.
 * Used for the buy/sell process, where it's important to have the freshest data.
 * The data are returned in the raw format.
 */
export function useVotePrice(contract?: Address, collective?: Address, amount?: number, paused = false) {
  const voteAmount = BigInt(amount ?? 1)

  // TODO: remove the default values?
  const call = {
    abi: BRACKET_ABI,
    address: contract,
  }
  const { data } = useReadContracts({
    allowFailure: false,
    contracts: [
      { ...call, functionName: "getBuyPrice", args: [collective ?? "0x0", voteAmount] } as const,
      { ...call, functionName: "getSellPrice", args: [collective ?? "0x0", voteAmount] } as const,
      { ...call, functionName: "getRedeemPrice", args: [collective ?? "0x0", voteAmount] } as const,
    ],
    query: {
      enabled: !!contract && !!collective && !paused,
      refetchInterval: DEFAULT_POLLING_INTERVAL,
    },
  })

  return {
    buyPrice: data?.[0],
    sellPrice: data?.[1],
    redeemPrices: data?.[2],
  }
}
