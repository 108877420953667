import { queryOptions, useQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"

async function getEntries(contractSlug: string) {
  const res = await client.api.contracts[":contractSlug"].entries.$get({ param: { contractSlug } })
  return await res.json()
}

function entriesQueryOptions(contractSlug: string) {
  return queryOptions({
    queryKey: ["entries", contractSlug],
    queryFn: () => getEntries(contractSlug),
    refetchInterval: DEFAULT_POLLING_INTERVAL,
  })
}

export function useEntries(contractSlug: string) {
  return useQuery(entriesQueryOptions(contractSlug))
}
